/*__________________________________________________________________________________________________________________________________________________________________________*/

type CallbackFn = (callback: Function) => unknown;

export const waitAll =
  (...functionsMap: CallbackFn[]) =>
  (callback: (results: unknown[]) => void): void => {
    const results: unknown[] = [];
    let counter = 0;
    functionsMap.forEach((func, index) => {
      func((result: unknown) => {
        results[index] = result;
        counter += 1;

        if (counter === functionsMap.length) {
          callback(results);
        }
      });
    });
  };

export const isLocalStorageEnabled = () => {
  const test = 'x';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (err) {
    return false;
  }
};

export const isCorsEnabled = () =>
  'XMLHttpRequest' in window && 'withCredentials' in new XMLHttpRequest();
