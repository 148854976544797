/*_________________________________________*/
import flow from '../shared/utils/flow';
import { activeTimeSpentOnPageTimer } from '../entry/visitorState/visitorActivityOnPage';

const externalPrefix = 'ext_';

//________________________________________________________________________
const stats = {
  //_____________________________________________________________
  customData() {
    const tmp = {};

    for (const c in this) {
      if (
        this[c] !== null &&
        typeof this[c] !== 'function' &&
        c.indexOf('ext_') !== -1 &&
        ['number', 'string', 'boolean'].includes(typeof this[c])
      ) {
        //_______
        tmp[c.substr(4).replace(/_/g, ' ')] = this[c];
      }
    }

    return JSON.stringify(tmp);
  },
  getProfile() {
    const tmp = {};

    for (const c in this) {
      if (
        this[c] !== null &&
        typeof this[c] !== 'function' &&
        c.indexOf('ext_') === -1
      ) {
        //_______
        if (c === 'navTime') {
          this[c] = Math.round(+new Date() / 1000 - this.connectionTime) * 1000;
          if (this[c] < 0) {
            this[c] = 3000;
          }
        }
        tmp[c] = this[c];
      }
    }

    return JSON.stringify(tmp);
  },
  activeTimeSpentOnPageTimer,
};

export const setCustomData = (key, value) => {
  stats[externalPrefix + key] = value;
};

export const deleteCustomData = (key) => {
  delete stats[externalPrefix + key];
};

export const getCustomData = (key) => stats[externalPrefix + key];

export const getAllCustomData = () =>
  flow(
    (obj) => Object.entries(obj),
    (pairs) =>
      pairs.filter(
        ([key, value]) =>
          key.startsWith(externalPrefix) &&
          ['number', 'string', 'boolean'].includes(typeof value),
      ),
    (pairs) =>
      pairs.map(([key, value]) => [key.substr(externalPrefix.length), value]),
    (obj) => obj.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  )(stats);

export const deleteAllCustomData = () => {
  Object.keys(getAllCustomData()).forEach(deleteCustomData);
};

export default stats;
