import { getEventManager as getDispatcher } from '../../shared/globals';

import scheduleOnMainJsThread from '../../../lib/scheduleOnMainTread';
import { DispatcherWithRegistry } from './event-manager/dispatcherWithRegistry';

export default () => {
  scheduleOnMainJsThread(() => {
    const dispatcher = getDispatcher() as DispatcherWithRegistry;
    import(
      /*__________________________________________*/ '../../livechat/src/gdpr/middleware'
    )
      .then(({ default: GDPRMiddleware }) => {
        scheduleOnMainJsThread(() => {
          GDPRMiddleware.register();
        })();
      })
      .catch(() => {
        dispatcher.emit('tag.livechat.stop');
        dispatcher.emit('tag.orchestrator.stop');
      });
  })();
};
