import { DispatcherWithRegistry } from './event-manager/dispatcherWithRegistry';
import throttle from '../../shared/utils/throttle';

const DEFAULT_DIRTY_CHECK_DELAY = 500;
const NAVIGATE_THROTTLING_DELAY = 1000;
/*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
const createSpaManager = (
  dispatcher: DispatcherWithRegistry,
  enableAutoNavigate: boolean,
) => {
  let timeoutId: number | undefined;
  let oldLocation = document.location.href;
  let targetingLoaded = false;

  dispatcher.once('targeting.bundle.loaded', () => {
    targetingLoaded = true;
  });

  /*__________________________________________________________________________________________________________*/
  function navigate(newScreen: string) {
    if (typeof newScreen !== 'string') {
      console.warn('navigate first argument must be a string'); //___________________________________
      return;
    }

    if (targetingLoaded) {
      dispatcher.emit('visitor.navigate.start', newScreen);
    } else {
      setTimeout(navigate, 100, newScreen);
    }
  }

  function startUrlWatcher(delay: number) {
    const newLocation = document.location.href;
    if (oldLocation !== newLocation) {
      navigate(newLocation);
      oldLocation = newLocation;
    }
    timeoutId = window.setTimeout(() => startUrlWatcher(delay), delay);
  }

  function stopNavigateWatcher() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  const throttledNavigate = throttle(navigate, NAVIGATE_THROTTLING_DELAY);

  return {
    startUrlWatcher: (dirtyCheckDelay = DEFAULT_DIRTY_CHECK_DELAY) => {
      //_________________________________________________________________________
      if (enableAutoNavigate) {
        startUrlWatcher(dirtyCheckDelay);
      }
    },

    stopNavigateWatcher,

    navigate: throttledNavigate,
  };
};

export default createSpaManager;

export type SpaManager = ReturnType<typeof createSpaManager>;
