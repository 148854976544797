export const selectOpt = (selector: string): HTMLElement | null => {
  if (!selector) {
    return null;
  }

  try {
    return window.document.querySelector(selector);
  } catch (e) {
    return null;
  }
};

export const elementTextOrVal = (element: HTMLElement | null) => {
  return element
    ? element.textContent || (element as HTMLInputElement).value
    : null;
};
