import { selectOpt, elementTextOrVal } from './domHelpers';

import {
  inSiteTypes,
  inSiteAggregationTypes,
} from '../../targeting/src/visitor/enums';
import createIntervalBasedDomObserver from './intervalBasedDomObserver';
import {
  getCustomData,
  setCustomData,
  //_____________________________
} from '../visitorState';
import * as customDataConfigService from '../customDataConfigService';
import Store from '../../shared/Store';
import { ValueOf } from '../../shared/types/utils';

const previousPagePrefix = 'prev_';
const observerInterval = 850;
const isNil = (val: unknown) => val == null;

let observer: ReturnType<typeof createIntervalBasedDomObserver> | null;

const hydrateCustomDataFromStore = (
  visitorStateKey: string,
  storeKey: string,
) => {
  const storedValue = Store.get(storeKey);
  if (typeof storedValue !== 'undefined') {
    setCustomData(visitorStateKey, storedValue);
  }
};

/*________________________________________________________*/
export const loadStateIntoVisitorState = () => {
  customDataConfigService
    .getInSite()
    .forEach(({ field: key, valtype: aggregationType }) => {
      switch (aggregationType) {
        case inSiteAggregationTypes.MIN:
        case inSiteAggregationTypes.MAX:
        case inSiteAggregationTypes.COUNT:
          hydrateCustomDataFromStore(key, key);
          break;
        case inSiteAggregationTypes.PREVIOUS_PAGE:
          hydrateCustomDataFromStore(key, previousPagePrefix + key);
          break;
        case inSiteAggregationTypes.CURRENT:
        default:
        //_________________________________________________
      }
    });
};

const parseAndSanitizeDomRawValue = (
  type: ValueOf<typeof inSiteTypes>,
  rawValue: string,
) => {
  //_____________________________________________________________
  //_______________________________________________________________________________________________________________________________________________________
  //_______________________________________________________________________________
  const commaToDot = (v: string) => v.replace(/,/g, '.');

  switch (type) {
    case inSiteTypes.INTEGER:
      return parseInt(commaToDot(rawValue), 10);
    case inSiteTypes.FLOAT:
      return parseFloat(commaToDot(rawValue));
    case inSiteTypes.STRING:
    default:
      return rawValue;
  }
};

/*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
const aggregateMutationIntoVisitorStateAndStore = (
  key: string,
  aggregationType: ValueOf<typeof inSiteAggregationTypes>,
  newValue: string | number | null,
  needle: string,
) => {
  const currentValue = getCustomData(key);

  switch (aggregationType) {
    case inSiteAggregationTypes.MIN:
      if (isNil(currentValue) || (newValue && newValue < currentValue)) {
        Store.set(key, newValue);
        setCustomData(key, newValue);
        return true;
      }
      break;
    case inSiteAggregationTypes.MAX:
      if (isNil(currentValue) || (newValue && newValue > currentValue)) {
        Store.set(key, newValue);
        setCustomData(key, newValue);
        return true;
      }
      break;
    case inSiteAggregationTypes.COUNT:
      if (!isNil(newValue) && newValue === needle) {
        Store.set(key, (currentValue || 0) + 1);
        setCustomData(key, (currentValue || 0) + 1);
        return true;
      }
      break;
    case inSiteAggregationTypes.PREVIOUS_PAGE:
      Store.set(previousPagePrefix + key, newValue);
      break;
    case inSiteAggregationTypes.CURRENT:
    default:
      if (!isNil(newValue)) {
        setCustomData(key, newValue);
      }
      return true;
  }

  return false;
};

const inSiteCustomDataConfigToStateUpdater = ({
  field: key,
  path: cssSelector,
  type,
  valtype: aggregationType,
  val: needle,
}: {
  field: string;
  path: string;
  type: string;
  valtype: ValueOf<typeof inSiteAggregationTypes>;
  val: string;
}) => {
  const onChange = () => {
    const rawNewValue = elementTextOrVal(selectOpt(cssSelector));
    const newValue = !isNil(rawNewValue)
      ? parseAndSanitizeDomRawValue(type, rawNewValue!)
      : null;

    return aggregateMutationIntoVisitorStateAndStore(
      key,
      aggregationType,
      newValue,
      needle,
    );
  };

  return { cssSelector, onChange };
};

/*___________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const observeInSiteCustomData = (
  onChangeCallback: Function,
  interval = observerInterval,
) => {
  const stateUpdaters = customDataConfigService
    .getInSite()
    .map(inSiteCustomDataConfigToStateUpdater);

  //________________________________________________________________________________________________
  stateUpdaters.forEach(({ onChange }) => onChange());

  const subscriptions = stateUpdaters.map(({ cssSelector, onChange }) => ({
    cssSelector,
    onChange: () => {
      const hasUpdated = onChange();
      if (hasUpdated) {
        onChangeCallback();
      }
    },
  }));

  if (subscriptions.length) {
    observer = createIntervalBasedDomObserver(subscriptions, interval);
  }
};

/*__________________________________________________________________________*/
export const unsubscribeAll = () => {
  if (observer) {
    observer.destroy();
    observer = null;
  }
};
