import { ThirdPartyCookies } from '../../../entry/types';
import { GlobalIdz } from '../../../shared/types/globalTypes';
import createFirstPartyCookieStorageClient from './client/firstPartyCookieStorage';
import { StorageAPI } from './typings';
/*_______________________________________________________*/

export default async (
  idz: GlobalIdz,
  thirdPartyCookies?: ThirdPartyCookies,
): Promise<StorageAPI> => {
  const storageClient = idz.firstPartyCookieEnabled
    ? createFirstPartyCookieStorageClient(idz.website_id, thirdPartyCookies)
    : await import(
        /*___________________________________________________*/ './client/thirdPartyCookieStorage'
      ).then(({ default: createThirdPartyCookieStorage }) =>
        createThirdPartyCookieStorage(idz.curlang, idz.website_id),
      );

  idz.define('storage', () => storageClient);

  return storageClient;
};
