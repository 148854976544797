import {
  TranslationsPaths,
  translationsPaths,
} from '@iadvize/livechat-translations-types';
import { Translations } from '@iadvize/translations-library';
import { buildHttpUrl } from '../../../entry/buildUrl/getUrls';
import retrier from '../../../shared/utils/retrier';

/*________________________________________________________________________________________________________________________________________________________________*/
export default (language: keyof TranslationsPaths): Promise<Translations> => {
  const url = (translationsPaths[language] || translationsPaths.en).replace(
    'https://static.iadvize.com/',
    '',
  );
  return retrier(() =>
    fetch(buildHttpUrl(`static/${url}`)).then(
      (r) => r.json() as Promise<Translations>,
    ),
  );
};
