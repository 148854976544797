import { ValueOf } from '../../../shared/types/utils';
import CustomEventEmitter, {
  Dispatcher,
} from '../../../shared/utils/CustomEventEmitter';
import { AllEvents } from './types';

//________________________________________________________________
type CustomEventMap = Record<string, (...args: any[]) => void>;

export const namespaces = {
  dispatcher: 'dispatcher',
  mirroring: 'mirroring',
  xmpp: 'xmpp',
  conversations: 'conversations',
  templates: 'templates',
  call: 'call',
  chatCoreV2: 'chatCoreV2',
  orchestrator: 'orchestrator',
} as const;

type Namespace = ValueOf<typeof namespaces>;

/*______________________________________________________________________*/
const registry: Partial<Record<Namespace, Function[]>> = {};

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const dispatcherWithRegistryFactory = <
  CustomEvents extends CustomEventMap = AllEvents,
>(
  namespace: Namespace = 'dispatcher',
): DispatcherWithRegistry<CustomEvents> => {
  const dispatcher: Dispatcher<CustomEvents> = CustomEventEmitter('dispatcher');

  if (!registry[namespace]) {
    registry[namespace] = [];
  }
  const namespacedRegistry = registry[namespace]!;

  return {
    ...dispatcher,
    on: <CE extends keyof CustomEvents>(
      event: CE,
      callback: CustomEvents[CE],
    ) => {
      namespacedRegistry.push(() => dispatcher.off(event, callback));
      return dispatcher.on(event, callback);
    },
    once: <CE extends keyof CustomEvents>(
      event: CE,
      callback?: CustomEvents[CE],
    ) => {
      namespacedRegistry.push(() => {
        if (callback) {
          dispatcher.off(event, callback);
        }
      });
      return dispatcher.once(event, callback);
    },
    off: <CE extends keyof CustomEvents>(
      event: CE,
      callback: CustomEvents[CE],
    ) => dispatcher.off(event, callback),
    registry: () => namespacedRegistry,
    clear: () => {
      namespacedRegistry.forEach((fn) => {
        fn();
      });
      namespacedRegistry.length = 0;
    },
    emit: dispatcher.emit,
    /*__________________________________________________________________________________*/
    setTimeout: (callback: Function, delay: number) => {
      const timeoutId = setTimeout(callback, delay);
      namespacedRegistry.push(() => clearTimeout(timeoutId));
      return timeoutId;
    },
    registerCleaningFunction: (cleaningFunction: () => void) => {
      namespacedRegistry.push(cleaningFunction);
    },
  };
};

export type DispatcherWithRegistry<
  CustomEvents extends CustomEventMap = AllEvents,
> = Dispatcher<CustomEvents> & {
  registry: () => typeof registry[Namespace];
  clear: () => void;
  setTimeout: (callback: Function, delay: number) => number;
  registerCleaningFunction: (cleaningFunction: () => void) => void;
};
