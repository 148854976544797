import { StorageType } from '../../../../shared/types/storageType';

type StorageFlow = {
  type: StorageType;
  next?: readonly StorageFlow[];
};

const FIRST_PARTY = 'FIRST_PARTY';
const THIRD_PARTY = 'THIRD_PARTY';
const FALLBACK = 'FALLBACK';
const MEMORY = 'MEMORY';
const SESSION = 'SESSION';

const firstPartyFlow: StorageFlow = {
  type: FIRST_PARTY,
  next: [
    {
      //_____________________________________
      type: `${FIRST_PARTY}_${MEMORY}_${FALLBACK}`,
      next: [
        {
          //_________________________________________
          type: FIRST_PARTY,
        },
      ],
    },
    {
      //______________________________________
      type: `${FIRST_PARTY}_${SESSION}_${FALLBACK}`,
      next: [
        {
          //__________________________________________
          type: FIRST_PARTY,
        },
      ],
    },
  ],
};

const thirdPartyFlow: StorageFlow = {
  type: THIRD_PARTY,
  next: [
    {
      //_________________________
      type: `${THIRD_PARTY}_${MEMORY}_${FALLBACK}`,
      next: [
        {
          //____________________________
          type: THIRD_PARTY,
        },
      ],
    },
  ],
};

const thirdPartyFirstPartyFallbackFlow: StorageFlow = {
  //____________________________
  type: `${THIRD_PARTY}_${FIRST_PARTY}_${FALLBACK}`,
  next: [
    {
      //_________________________
      type: `${THIRD_PARTY}_${MEMORY}_${FALLBACK}`,
      next: [
        {
          //____________________________
          type: `${THIRD_PARTY}_${FIRST_PARTY}_${FALLBACK}`,
        },
      ],
    },
  ],
};

let inMemoryStorageType: StorageType | null = null;
let entryFlow: StorageFlow | null = null;
const entryFlowMap: Partial<Record<StorageType, StorageFlow>> = {
  [FIRST_PARTY]: firstPartyFlow,
  [THIRD_PARTY]: thirdPartyFlow,
  [`${THIRD_PARTY}_${FIRST_PARTY}_${FALLBACK}`]:
    thirdPartyFirstPartyFallbackFlow,
};

const findStorage = (
  storageType: StorageType,
  flow: StorageFlow | undefined,
): StorageFlow | undefined =>
  flow?.type === storageType
    ? flow
    : flow?.next?.find((nextFlow) => findStorage(storageType, nextFlow));

export const setStorageType = (storageType: StorageType) => {
  //____________________________________________________________________
  if (!entryFlow) {
    entryFlow = entryFlowMap[storageType]!;
    inMemoryStorageType = storageType;
    if (entryFlow === null) {
      throw new Error('Unauthorized flow');
    }
    return;
  }
  //____________________
  if (storageType === inMemoryStorageType) {
    return;
  }
  //__________________________________________________________________________________
  if (
    storageType === entryFlow.type &&
    (inMemoryStorageType?.endsWith(`${MEMORY}_${FALLBACK}`) ||
      inMemoryStorageType?.endsWith(`${SESSION}_${FALLBACK}`))
  ) {
    inMemoryStorageType = entryFlow.type;
    return;
  }

  //__________________________________________________________________________
  const currentStorage = findStorage(inMemoryStorageType!, entryFlow)!;
  const nextStorage = findStorage(storageType, currentStorage);
  inMemoryStorageType = nextStorage?.type || inMemoryStorageType;
};

export const getStorageType = (): StorageType | null => inMemoryStorageType;

/*__________________________________________________*/
export const resetStorageType = () => {
  inMemoryStorageType = null;
  entryFlow = null;
};
