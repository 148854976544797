/*_____________________________*/
const tagLoadingMark = 'idz-launched-tag-loading';

const userTimingAPIExists = () => {
  try {
    const perf = window.top?.performance;
    return !!(perf && perf.getEntriesByName);
  } catch (e) {
    return false;
  }
};

const isTimingEnabled = () => {
  try {
    const perf = window.top?.performance;
    return perf && perf.getEntriesByName(tagLoadingMark).length > 0;
  } catch (e) {
    return false;
  }
};

const shouldTrackTiming = userTimingAPIExists() && isTimingEnabled();

export const PerformanceMarks = {
  FETCH_STATIC_CONFIG: 'idz-fetchStaticConfig',
  FETCH_DYNAMIC_CONFIG: 'idz-fetchDynamicConfig',
  LAUNCHER_LOAD_CURRENT_VUID: 'idz-loadCurrentVuidInGlobalConfiguration',
  LAUNCHER_LOAD_SCRIPTS: 'idz-launcherLoadScripts',

  TARGETING_LOAD: 'idz-loadTargeting',
  TARGETING_PARSE_RULES: 'idz-targetingParsingRules',
  TARGETING_LOAD_ORCHESTRATOR: 'idz-targetingLoadOrchestrator',
  TARGETING_RUNNER: 'idz-targetingRunnerRun',
  TARGETING_RULES_BATCH: 'idz-targetingRulesBatch',
  TARGETING_RULES_BATCH_FETCH_AVAILABILITY:
    'idz-targetingRulesBatchFetchAvailability',
  TARGETING_RULES_BATCH_FETCH_OPPORTUNITY:
    'idz-targetingRulesBatchFetchOpportunity',
  TARGETING_RULES_BATCH_EXECUTION: 'idz-targetingRulesBatchExecution',

  NOTIFICATION_DISPLAY: 'idz-targetingDisplayNotification',
  CHATBOX_DISPLAY: 'idz-chatboxDisplay',
} as const;

type ValueOf<T> = T[keyof T];
export type PerformanceMark = ValueOf<typeof PerformanceMarks>;

export const addPerformanceMark = (markName: PerformanceMark) => {
  try {
    if (shouldTrackTiming) {
      window.top?.performance.mark(markName);
      window.top?.performance.measure(tagLoadingMark, markName);
    }
  } catch (e) {}
};

export const startPerformanceMeasure = (markName: PerformanceMark) => {
  try {
    if (shouldTrackTiming) {
      window.top?.performance.mark(`${markName}-start`);
    }
  } catch (e) {}
};

export const endPerformanceMeasure = (markName: PerformanceMark) => {
  try {
    if (shouldTrackTiming) {
      window.top?.performance.mark(`${markName}-end`);
      window.top?.performance.measure(
        markName,
        `${markName}-start`,
        `${markName}-end`
      );
    }
  } catch (e) {}
};
