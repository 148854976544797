import { ValueOf } from './utils';

export type JwtIdentity = {
  jwt: string;
  vuid: string;
  localExpireAt: number;
  type: ValueOf<typeof authenticationTypeEnum>;
};

export const authenticationTypeEnum = {
  SECURED: 'SECURED_AUTHENTICATION',
  SIMPLE: 'SIMPLE_AUTHENTICATION',
  ANONYMOUS: 'ANONYMOUS',
} as const;

export const authenticationResultEnum = {
  SUCCESS: 'authentication-success',
  FAILURE: 'authentication-failure',
} as const;

export const authenticationStatusEnum = {
  AUTHENTICATED: 'authenticated',
  AUTHENTICATING: 'authenticating',
  UNAUTHENTICATED: 'unauthenticated',
} as const;
