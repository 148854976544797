/*_______________________________________________________*/
/*_____________________________*/
const deserialize = (value: string | null) => {
  if (typeof value !== 'string') {
    return undefined;
  }

  try {
    return JSON.parse(value);
  } catch (e) {}

  return undefined;
};

export default {
  set: <Val = any>(key: string, val: Val): void => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(val));
    } catch (e) {}
  },
  get: <Val = any>(key: string): Val | undefined =>
    deserialize(window.sessionStorage.getItem(key)),
  remove: (key: string): void => {
    window.sessionStorage.removeItem(key);
  },
  clear: (): void => {
    window.sessionStorage.clear();
  },
  keys: (): string[] => Object.keys(window.sessionStorage),
};
