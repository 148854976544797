import get from '../entry/publicMethods/get/get';
import { VisitorIdentityService } from '../live/src/visitor-identity/VisitorIdentityService';

import { getIdz, getPlatform, getWebsiteId } from '../shared/globals';
import fetchWithTimeout from '../shared/utils/fetchWithTimeout';

/*_________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const setVisitorState = async () => {
  const idz = getIdz();

  const jwtIdentity = await idz
    .require<VisitorIdentityService>('visitorIdentityService')
    .getJwtIdentity();

  const setVisitorStateUrl = `${
    idz.chaturl
  }api/website/${getWebsiteId()}/visitor/${get(
    'visitor:sourceId',
  )}/visitor-state`;

  return fetch(setVisitorStateUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(jwtIdentity?.jwt && { Authorization: `Bearer ${jwtIdentity.jwt}` }),
    },
    body: JSON.stringify({ lang: idz.curlang }),
  }).then((response) => response.json());
};

const toGraphqlValueField = (value: number | string | boolean) => {
  if (Number.isInteger(value)) {
    return { intValue: value };
  }
  if (typeof value === 'number') {
    return { floatValue: value };
  }
  if (typeof value === 'string') {
    return { stringValue: value };
  }
  if (typeof value === 'boolean') {
    return { booleanValue: value };
  }

  return {};
};

/*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const saveConversationCustomData = async (
  conversationId: string,
  customData: Record<string, string>,
): Promise<void> => {
  const idz = getIdz();
  const websiteId = getWebsiteId();
  const graphqlCustomData = Object.entries(customData).map(([key, value]) => ({
    key,
    ...toGraphqlValueField(value),
  }));

  if (!graphqlCustomData.length) {
    return;
  }

  const body = JSON.stringify({
    query: `
mutation(
$vuid: String!,
$websiteId: Int!,
$conversationId: UUID!,
$customData: [VisitorConversationCustomDataInput!]!
) {
visitorConversationCustomDataSet(
  sourceVisitorId: $vuid,
  projectId: $websiteId,
  source: ONSITE,
  conversationId: $conversationId,
  customData: $customData) {
  conversationId
}
}`,
    variables: {
      vuid: idz.vuid,
      websiteId,
      conversationId,
      customData: graphqlCustomData,
    },
  });

  const headers: {
    Accept: string;
    Authorization?: string;
    'Content-Type'?: string;
  } = {
    Accept:
      'application/vnd.iadvize.visitor-conversation-custom-data-preview+json',
    'Content-Type': 'application/json',
  };

  fetchWithTimeout(`${getIdz().graphql.url}?platform=${getPlatform()}`, 5000, {
    method: 'POST',
    headers,
    body,
  });
};
