import { WebsiteId } from '../../../../../shared/types/utils';
import { StorageAPI, StorageAPIValueObject, StorageKind } from '../../typings';
import getTopDomain from '../../../../../storage/cookieStorage/getTopDomain';
import { getCookie } from '../../../../../storage/cookieStorage/getCookie';

import { isThirdPartyKey } from '../common';

import { applyCommand } from '../../../../../storage/storageAPI';
import noop from '../../../../../shared/utils/noop';
import { ThirdPartyCookies } from '../../../../../entry/types';

function createFirstPartyCookieStorageClient(
  websiteId: WebsiteId,
  thirdPartyCookies?: ThirdPartyCookies,
): StorageAPI {
  const toPrefixName = (key: string) => `iadvize-${websiteId}-${key}`;
  const set = (
    key: string,
    _value: string | StorageAPIValueObject,
    callback: () => void,
    kind: StorageKind = 'session',
  ): void => {
    const domain = getTopDomain();
    const name = toPrefixName(key);
    const isStringValue = typeof _value === 'string';
    const value = isStringValue
      ? (_value as string)
      : (_value as StorageAPIValueObject).value;
    const ttl = isStringValue
      ? undefined
      : String((_value as StorageAPIValueObject).ttl);
    applyCommand({
      domain,
      key: name,
      kind,
      name: 'set',
      ttl,
      value,
    });
    callback?.();
  };

  const del = (
    key: string,
    callback: () => void,
    kind: StorageKind = 'session',
  ) => {
    const name = toPrefixName(key);
    const domain = getTopDomain();
    applyCommand({ kind, name: 'del', key: name, domain });
    if (kind === 'cookie') {
      //______________________________________________
      del(key, callback, 'session');
      return;
    }
    callback?.();
  };

  const get = async (
    key: string,
    callback: (value: string | null) => void,
    kind: StorageKind = 'session',
  ): Promise<void> => {
    const name = toPrefixName(key);
    if (kind === 'session') {
      callback(sessionStorage.getItem(name));
      return;
    }
    const cookie = getCookie(name);
    if (cookie || !isThirdPartyKey(key)) {
      callback(cookie);
      return;
    }

    const thirdPartyCookie = thirdPartyCookies?.[key] ?? null;
    if (thirdPartyCookie) set(key, thirdPartyCookie, noop, 'cookie');
    callback(thirdPartyCookie);
  };

  return {
    del,
    get,
    set,
  };
}

export default createFirstPartyCookieStorageClient;
