import { InvalidTransactionError } from './errors';
import {
  RETRY_COUNT_STORAGE_KEY,
  TRANSACTION_STORAGE_KEY,
  TRANSACTION_COOKIES_TTL,
} from './cookiesSettings';
import { StorageAPI } from '../storage/typings';
import noop from '../../../shared/utils/noop';
import { Transaction } from './types';

/*____________________________________________________________________________________________________*/
const computeBackoff = (numberOfRetries = 0) =>
  Math.min(1.25 ** numberOfRetries, 10) * 1000;

export default (storage: StorageAPI, makeTransactionRequest: Function) => {
  const setTransactionInStorage = (
    transaction: Transaction,
    callback: () => void,
  ) =>
    storage.set(
      TRANSACTION_STORAGE_KEY,
      {
        value: JSON.stringify(transaction),
        ttl: TRANSACTION_COOKIES_TTL,
      },
      callback,
      'cookie',
    );

  const getFromStorage = async () => {
    const rawTransaction = await new Promise<string | null>((resolve) => {
      storage.get(TRANSACTION_STORAGE_KEY, resolve, 'cookie');
    });
    const rawRetryCount = await new Promise<string | null>((resolve) => {
      storage.get(RETRY_COUNT_STORAGE_KEY, resolve, 'cookie');
    });
    return { rawTransaction, rawRetryCount };
  };

  const setRetryCountIfNeeded = (retryCount: number, callback: () => void) =>
    storage.get(
      RETRY_COUNT_STORAGE_KEY,
      (storedRetryCount) => {
        if (!storedRetryCount || Number(storedRetryCount) < retryCount) {
          storage.set(
            RETRY_COUNT_STORAGE_KEY,
            { value: `${retryCount}`, ttl: TRANSACTION_COOKIES_TTL },
            callback,
            'cookie',
          );
        } else {
          callback();
        }
      },
      'cookie',
    );

  const clearTransactionStorage = (callback = noop) => {
    storage.del(
      TRANSACTION_STORAGE_KEY,
      () => storage.del(RETRY_COUNT_STORAGE_KEY, callback, 'cookie'),
      'cookie',
    );
  };

  /*_____________________________________________________________________________________________*/
  const sendTransactionWithRetries = (
    transaction: Transaction,
    numberOfRetries = 0,
    callback: (err?: unknown) => void = noop,
  ) => {
    makeTransactionRequest(transaction, (err: unknown) => {
      //_________________________________________

      if (
        err &&
        !((err as { name: string })?.name === InvalidTransactionError.name) &&
        numberOfRetries < 15
      ) {
        setTimeout(
          () =>
            setRetryCountIfNeeded(numberOfRetries + 1, () =>
              sendTransactionWithRetries(
                transaction,
                numberOfRetries + 1,
                callback,
              ),
            ),
          computeBackoff(numberOfRetries),
        );
      } else {
        clearTransactionStorage(() => callback(err));
      }
    });
  };

  /*_______________________________________________________________________________________________________________________________________*/
  const storeAndSendTransactionWithRetries = (
    transaction: Transaction,
    callback = noop,
  ) => {
    setTransactionInStorage(transaction, () => {
      sendTransactionWithRetries(transaction, 0, callback);
    });
  };

  /*__________________________________________________________________________________________________________________________________________*/
  const tryRecoveringTransaction = async () => {
    const { rawTransaction, rawRetryCount } = await getFromStorage();
    let transaction;
    if (rawTransaction) {
      try {
        transaction = JSON.parse(rawTransaction);
      } catch (e) {
        //______________________________________________________________
      }
    }

    if (transaction) {
      const retryCount = rawRetryCount ? parseInt(rawRetryCount, 10) : 0;
      sendTransactionWithRetries(transaction, retryCount);
    }
  };

  return {
    storeAndSendTransactionWithRetries,
    tryRecoveringTransaction,
  };
};
