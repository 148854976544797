import { v4 as uuid } from 'uuid';
import { StorageAPI } from '../storage/typings';
import { JwtIdentity } from '../../../shared/types/visitorIdentity';
import { DEFAULT_TTL_S } from '../../../storage/cookieStorage/constants';
import {
  getDecodedCookieValue,
  getEncodedCookieValue,
} from '../../../storage/cookieStorage/utils';
import {
  DecodedCookieObject,
  EncodedCookieObject,
} from '../../../storage/cookieStorage/types';
import { setStorageType as _setStorageType } from '../storage/state/storageType';
import {
  ExpressedConsent,
  StringOrBoolConsent,
} from '../../../shared/types/consent';
import { getExpressedConsentFromStringOrBoolean } from '../../../shared/consentHelpers';
import { PublicPropertiesStore } from '../../../shared/types/publicMethods';
import {
  getInMemoryJwtCookie,
  setInMemoryJwtCookie,
} from '../../../entry/authentication/inMemoryJwtCookie';

let inMemoryVisitorCookiesConsent: ExpressedConsent = null;

type IdentityObject = {
  vuid: string;
  deviceId?: string;
};

/*______________________________________________________________________________________________________________________________________________*/
export default (
  url: string,
  storage: StorageAPI,
  publicPropertiesStore: PublicPropertiesStore,
  isDeviceIdEnabled: boolean,
  isFirstPartyStorage: boolean,
) => {
  const identityObjectStorageKey = 'vuid';
  const jwtIdentityStorageKey = 'jwtIdentity';
  const consentStorageKey = 'consent';

  //_____________________________________________________________
  //_______________________________________________________________________
  let shouldStoreInCookie = true;
  let inMemoryVuidCookie: string | null = null;

  let inMemoryDeviceId: string;
  const getInMemoryDeviceId = () => {
    if (!inMemoryDeviceId) {
      inMemoryDeviceId = uuid();
    }

    return inMemoryDeviceId;
  };

  const isVuidValid = (vuid: string | null) =>
    vuid && /^[0-9a-z]{45}$/.test(vuid);

  const getValidVuidOrNull = (vuidOrIdObj: string | null | undefined) => {
    if (!vuidOrIdObj) return null;

    if (isVuidValid(vuidOrIdObj)) {
      return vuidOrIdObj;
    }
    try {
      const idObj: DecodedCookieObject<IdentityObject> = getDecodedCookieValue(
        vuidOrIdObj as EncodedCookieObject,
      );
      return isVuidValid(idObj.vuid) ? idObj.vuid : null;
    } catch (e) {
      return null;
    }
  };

  const getValidDeviceId = (cookieValue: string | null | undefined) => {
    if (!cookieValue) {
      return getInMemoryDeviceId();
    }

    try {
      const idObj: DecodedCookieObject<IdentityObject> = getDecodedCookieValue(
        cookieValue as EncodedCookieObject,
      );
      return idObj.deviceId || getInMemoryDeviceId();
    } catch (e) {
      return getInMemoryDeviceId();
    }
  };

  /*_____________________________________________________________________________________________________________*/
  const getAnonymousVuid = async (): Promise<string | null> => {
    if (!shouldStoreInCookie) {
      return inMemoryVuidCookie;
    }

    return new Promise((resolve) => {
      storage.get(
        identityObjectStorageKey,
        async (cookieValue) => {
          //________________________________________________________________________________________
          if (!cookieValue && inMemoryVuidCookie) {
            const validVuidOrNull = getValidVuidOrNull(inMemoryVuidCookie);
            resolve(validVuidOrNull);
          }

          const validVuidOrNull = getValidVuidOrNull(cookieValue);

          //______________________________________________________________________________________________________
          if (
            validVuidOrNull !== null &&
            validVuidOrNull !== cookieValue &&
            !isDeviceIdEnabled
          ) {
            storage.set(
              identityObjectStorageKey,
              validVuidOrNull,
              () => resolve(validVuidOrNull),
              'cookie',
            );
          } else {
            resolve(validVuidOrNull);
          }
        },
        'cookie',
      );
    });
  };

  const setDeviceId = async (): Promise<string> => {
    if (!shouldStoreInCookie || !isDeviceIdEnabled) {
      return getInMemoryDeviceId();
    }

    return new Promise((resolve) => {
      storage.get(
        identityObjectStorageKey,
        (previousCookieValue) => {
          const vuid = getValidVuidOrNull(previousCookieValue);

          storage.set(
            identityObjectStorageKey,
            getEncodedCookieValue({ vuid, deviceId: getInMemoryDeviceId() }),
            () => resolve(getInMemoryDeviceId()),
            'cookie',
          );
        },
        'cookie',
      );
    });
  };

  const getDeviceId = async (): Promise<string> => {
    if (!shouldStoreInCookie) {
      return getInMemoryDeviceId();
    }

    return new Promise((resolve) => {
      storage.get(
        identityObjectStorageKey,
        async (cookieValue) => {
          if (!cookieValue) {
            const deviceId = await setDeviceId();
            return resolve(deviceId);
          }

          try {
            const idObj: DecodedCookieObject<IdentityObject> =
              getDecodedCookieValue(cookieValue as EncodedCookieObject);

            if (!idObj.deviceId) {
              const deviceId = await setDeviceId();
              return resolve(deviceId);
            }

            return resolve(idObj.deviceId);
          } catch (e) {
            const deviceId = await setDeviceId();
            return resolve(deviceId);
          }
        },
        'cookie',
      );
    });
  };

  /*_____________________________________________________________________________________________________________________________________________________________________________________*/
  const setAnonymousVuid = async (
    vuid: string,
    ttl: number = DEFAULT_TTL_S,
  ): Promise<void> => {
    inMemoryVuidCookie = vuid;

    if (!shouldStoreInCookie) {
      return;
    }

    return new Promise((resolve) => {
      storage.get(
        identityObjectStorageKey,
        (previousCookieValue) => {
          const previousVuid = getValidVuidOrNull(previousCookieValue);
          const deviceId = getValidDeviceId(previousCookieValue);

          if (previousVuid !== vuid) {
            storage.set(
              identityObjectStorageKey,
              {
                value: isDeviceIdEnabled
                  ? getEncodedCookieValue({ vuid, deviceId })
                  : vuid,
                ttl,
              },
              () => resolve(),
              'cookie',
            );
          } else {
            resolve();
          }
        },
        'cookie',
      );
    });
  };

  /*_____________________________________________________________________*/
  const getCookieConsent = (): Promise<ExpressedConsent> =>
    new Promise((resolve) => {
      storage.get(
        consentStorageKey,
        (storedConsent) => {
          const consent = getExpressedConsentFromStringOrBoolean(
            storedConsent as StringOrBoolConsent,
          );
          if (inMemoryVisitorCookiesConsent !== consent) {
            publicPropertiesStore.dispatch('visitor:cookiesConsent', consent);
            inMemoryVisitorCookiesConsent = consent;
          }
          resolve(consent);
        },
        'cookie',
      );
    });

  /*____________________________________________________________________________________________________________________________________________________________________*/
  const getIsAcceptedCookieConsentSet = (): Promise<boolean> =>
    new Promise((resolve) => {
      storage.get(
        consentStorageKey,
        (storedConsent) => {
          resolve(storedConsent === 'true');
        },
        'cookie',
      );
    });

  /*________________________________________________*/
  const registerCookieConsent = (
    { hasConsented, ttl }: { hasConsented: boolean; ttl?: number },
    callback: () => void,
  ) => {
    storage.set(
      consentStorageKey,
      { value: String(hasConsented), ttl: ttl || DEFAULT_TTL_S },
      () => {
        inMemoryVisitorCookiesConsent = hasConsented;
        publicPropertiesStore.dispatch(
          'visitor:cookiesConsent',
          inMemoryVisitorCookiesConsent,
        );
        callback();
      },
      'cookie',
    );
  };

  const setJwtIdentity = async (
    jwtIdentity: JwtIdentity,
    ttl: number = DEFAULT_TTL_S,
  ): Promise<void> => {
    if (!isVuidValid(jwtIdentity.vuid)) {
      return;
    }

    setInMemoryJwtCookie(jwtIdentity);

    if (!shouldStoreInCookie) {
      return;
    }

    const payload = getEncodedCookieValue(jwtIdentity);

    return new Promise((resolve) => {
      storage.get(
        jwtIdentityStorageKey,
        (previousJwtIdentity) => {
          if (previousJwtIdentity !== payload) {
            storage.set(
              jwtIdentityStorageKey,
              { value: payload, ttl },
              () => resolve(),
              'cookie',
            );
          } else {
            resolve();
          }
        },
        'cookie',
      );
    });
  };

  const getJwtIdentity = async (): Promise<JwtIdentity | null> => {
    if (!shouldStoreInCookie) {
      return getInMemoryJwtCookie();
    }

    return new Promise((resolve) => {
      storage.get(
        jwtIdentityStorageKey,
        (json) => {
          let jwtIdentity: DecodedCookieObject<JwtIdentity> | null = null;
          if (json) {
            try {
              jwtIdentity = getDecodedCookieValue<JwtIdentity>(
                json as EncodedCookieObject,
              );
            } catch (e) {} //_________________________________
          } else if (getInMemoryJwtCookie()) {
            //_______________________________________________________________________________________________
            jwtIdentity =
              getInMemoryJwtCookie() as DecodedCookieObject<JwtIdentity>;
          }

          resolve(jwtIdentity);
        },
        'cookie',
      );
    });
  };

  const removeJwtIdentity = (): Promise<void> => {
    setInMemoryJwtCookie(null);
    return new Promise((resolve) => {
      storage.del(jwtIdentityStorageKey, resolve, 'cookie');
    });
  };

  /*_________________________________________________________________*/
  const extendAnonymousVuid = () => {
    return new Promise<void>((resolve) => {
      storage.get(
        identityObjectStorageKey,
        (previousVuid) => {
          if (previousVuid) {
            storage.set(
              identityObjectStorageKey,
              previousVuid,
              () => resolve(),
              'cookie',
            );
          } else {
            resolve();
          }
        },
        'cookie',
      );
    });
  };

  const setStorageType = (): void => {
    _setStorageType(isFirstPartyStorage ? 'FIRST_PARTY' : 'THIRD_PARTY');

    if (!shouldStoreInCookie) {
      _setStorageType(
        isFirstPartyStorage
          ? 'FIRST_PARTY_MEMORY_FALLBACK'
          : 'THIRD_PARTY_MEMORY_FALLBACK',
      );
    }
  };

  const storeIdentityInCookies = (storeInCookies: boolean, ttl?: number) => {
    shouldStoreInCookie = storeInCookies;
    setStorageType();

    const getSetAnonymousVuid = async (cb: () => void) => {
      const anonymousVuid = await getAnonymousVuid();
      if (anonymousVuid) {
        await setAnonymousVuid(anonymousVuid, ttl);
      }
      cb();
    };

    const getSetJwtIdentity = async (cb: () => void) => {
      const jwtIdentity = await getJwtIdentity();
      if (jwtIdentity) {
        await setJwtIdentity(jwtIdentity, ttl);
      }
      cb();
    };

    return new Promise<void>((resolve) => {
      if (shouldStoreInCookie) {
        getSetAnonymousVuid(() => getSetJwtIdentity(() => resolve()));
      } else {
        storage.del(
          jwtIdentityStorageKey,
          () => {
            storage.del(
              identityObjectStorageKey,
              () => {
                resolve();
              },
              'cookie',
            );
          },
          'cookie',
        );
      }
    });
  };

  return {
    getAnonymousVuid,
    setAnonymousVuid,
    getCookieConsent,
    registerCookieConsent,
    getIsAcceptedCookieConsentSet,
    setJwtIdentity,
    getJwtIdentity,
    removeJwtIdentity,
    extendAnonymousVuid,
    storeIdentityInCookies,
    getDeviceId,
  };
};
