import createTransactionService from './transactionService';
import { registerPublicMethods } from '../../../entry/iAdvizeInterface/iAdvizeInterface';
import { GlobalIdz } from '../../../shared/types/globalTypes';
import { StorageAPI } from '../storage/typings';

export default (
  idz: GlobalIdz,
  storage: StorageAPI,
  transactionCalls: GlobalIdz['recordTransaction']['calls'],
) => {
  const transactionService = createTransactionService(
    storage,
    idz,
    transactionCalls,
  );

  registerPublicMethods({
    recordTransaction: transactionService.recordTransaction,
  });

  return transactionService;
};
