import { getPublicPropertiesStore } from '../../entry/publicMethods/publicProperties/registerPublicProperties';
import { getIsFromLightMode } from '../../entry/visitorContextConfig/executionContext';
import {
  DisplayedNotification,
  HiddenNotification,
  MatchedEngagementRule,
} from '../../entry/visitorContextConfig/types';
import {
  getExecutedEngagementRules,
  onExecutedEngagementRulesChange,
} from '../../entry/visitorState/executedEngagementRules';
import { PublicPropertiesStore } from '../../shared/types/publicMethods';
import diffArrays from '../../shared/utils/diffArrays';
import { DispatcherWithRegistry } from './event-manager/dispatcherWithRegistry';
import { EngagementNotificationsDisplayed } from '../../shared/types/notifications';
import { onDisplayedNotificationsChange } from '../../entry/visitorState/displayedNotifications';
import { onHiddenNotificationsChange } from '../../entry/visitorState/hiddenNotifications';

export default function trackEngagementRules(
  dispatcher: DispatcherWithRegistry,
  publicPropertyStore: PublicPropertiesStore = getPublicPropertiesStore(),
) {
  const rulesChangedCallback = (
    executedEngagementRules: MatchedEngagementRule[],
    previouslyExecutedEngagementRules: MatchedEngagementRule[],
  ) => {
    const previousRuleIds = previouslyExecutedEngagementRules.map(
      ({ engagementRuleId }) => engagementRuleId,
    );
    const ruleIds = executedEngagementRules.map(
      ({ engagementRuleId }) => engagementRuleId,
    );
    const [, newlyTriggeredRules] = diffArrays(previousRuleIds, ruleIds);

    newlyTriggeredRules.forEach((engagementRuleId) => {
      const legacyId = window.iAdvize.proactifData.interactionRules.find(
        (rule) => rule.newId === engagementRuleId,
      )?.id;

      if (!getIsFromLightMode() && legacyId) {
        dispatcher.emit(
          'dataPipeline.engagementRuleCountExecute',
          `${legacyId}`,
        );
      }

      publicPropertyStore.dispatch(
        'engagementRule:triggered',
        engagementRuleId,
      );

      window.iAdvizeCallbacks?.onTargetingRuleTriggered?.({
        id: engagementRuleId,
      });
    });

    publicPropertyStore.dispatch(
      'engagementRules:triggered',
      executedEngagementRules.map(({ engagementRuleId }) => engagementRuleId),
    );
  };

  //________________________
  rulesChangedCallback(getExecutedEngagementRules(), []);

  //_________________________________
  onExecutedEngagementRulesChange(rulesChangedCallback);

  //__________________________________
  onDisplayedNotificationsChange(
    (
      displayedNotifications: DisplayedNotification[],
      previouslyDisplayedNotifications: DisplayedNotification[],
    ) => {
      const [, newlyDisplayedRules] = diffArrays(
        previouslyDisplayedNotifications,
        displayedNotifications,
      );

      newlyDisplayedRules.forEach(
        ({
          engagementRuleId,
          notificationId,
          type,
          channels,
          legacyId,
          action,
        }) => {
          if (legacyId) {
            dispatcher.emit('notification.displayed', {
              action,
              ruleId: legacyId,
            });
          }

          publicPropertyStore.dispatch('engagementNotification:displayed', {
            type: type === 'FIXED' ? 'CUSTOM_BUTTON' : type,
            id: notificationId,
            ruleId: engagementRuleId,
            channels,
          });
        },
      );

      const displayedNotificationsData = displayedNotifications.length
        ? [...displayedNotifications, ...previouslyDisplayedNotifications]
        : [];

      publicPropertyStore.dispatch(
        'engagementNotifications:displayed',
        displayedNotificationsData.map(
          ({ engagementRuleId, notificationId, type, channels }) => ({
            type:
              type === 'FIXED'
                ? 'CUSTOM_BUTTON'
                : (type as EngagementNotificationsDisplayed['type']),
            id: notificationId,
            ruleId: engagementRuleId,
            channels,
          }),
        ),
      );
    },
  );

  onHiddenNotificationsChange(
    (
      hiddenNotifications: HiddenNotification[],
      previouslyHiddenNotifications: HiddenNotification[],
    ) => {
      const [, newlyHiddenRules] = diffArrays(
        previouslyHiddenNotifications,
        hiddenNotifications,
      );

      newlyHiddenRules.forEach(({ legacyId, action, cause }) => {
        if (legacyId) {
          dispatcher.emit('notification.hidden', {
            action,
            ruleId: legacyId,
            cause,
          });
        }
      });
    },
  );
}
