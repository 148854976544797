import Bowser from 'bowser';
import scheduleOnMainJsThread from '../../lib/scheduleOnMainTread';
import { DEVICE_TYPE } from './constants';

export type DeviceInformation = {
  browser: string;
  version: number | 'unknown';
  OS: string;
  deviceType: 'mobile' | 'desktop' | 'tablet';
  major: string;
};
const convertParserDeviceTypeToGenericDeviceType = (
  deviceType: string | undefined,
) => {
  if (deviceType === Bowser.PLATFORMS_MAP.mobile) {
    return DEVICE_TYPE.MOBILE;
  }
  if (deviceType === Bowser.PLATFORMS_MAP.tablet) {
    return DEVICE_TYPE.TABLET;
  }
  return DEVICE_TYPE.DESKTOP;
};

let parserCache: Bowser.Parser.Parser | null = null;

function getDeviceParser(): Bowser.Parser.Parser {
  if (!parserCache) {
    parserCache = Bowser.getParser(window.navigator.userAgent);
  }
  return parserCache;
}

//____________________________________________________
scheduleOnMainJsThread(getDeviceParser)();

let deviceInformationCache: DeviceInformation | null = null;
export default (): DeviceInformation => {
  if (deviceInformationCache) return deviceInformationCache;
  const { browser, os, platform } = getDeviceParser().getResult();
  const { version, name } = browser;

  deviceInformationCache = {
    browser: name || 'unknown',
    version: version ? parseInt(version, 10) || 'unknown' : 'unknown',
    OS: os.name || 'unknown',
    deviceType: convertParserDeviceTypeToGenericDeviceType(platform.type),
    major: version?.split('.')?.[0] || 'unknown',
  };
  return deviceInformationCache;
};
