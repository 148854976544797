import scheduleOnMainJsThread from '../../../lib/scheduleOnMainTread';
import { ExpressedConsent } from '../../shared/types/consent';
import { PublicPropertiesStore } from '../../shared/types/publicMethods';
import { DispatcherWithRegistry } from './event-manager/dispatcherWithRegistry';

export default (
  dispatcher: DispatcherWithRegistry,
  publicPropertiesStore: PublicPropertiesStore,
  cookieConsent: ExpressedConsent,
  sendEventsOnInit: boolean,
) => {
  return new Promise<void>((resolve, reject) => {
    scheduleOnMainJsThread(() => {
      import(/*________________________________________*/ '../../dataPipeline')
        .then(({ default: createDataPipeline }) => {
          createDataPipeline(
            dispatcher,
            publicPropertiesStore,
            cookieConsent,
            sendEventsOnInit,
          );
          resolve();
        })
        .catch(reject);
    })();
  });
};
