import { VisitorIdentityService } from './VisitorIdentityService';
import debounce from '../../../shared/utils/debounce';
import { DispatcherWithRegistry } from '../event-manager/dispatcherWithRegistry';
import { registerSetter } from '../../../entry/publicMethods/set/set';
import deprecate from '../../../shared/utils/deprecate';
import { DEFAULT_TTL_S } from '../../../storage/cookieStorage/constants';
import { getExpressedConsentFromStringOrBoolean } from '../../../shared/consentHelpers';
import { StringOrBoolConsent } from '../../../shared/types/consent';

export default (
  visitorIdentityService: VisitorIdentityService,
  dispatcher: DispatcherWithRegistry,
  shouldStoreCookies: boolean,
) => {
  /*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const setVisitorCookiesConsent = async (
    hasConsented: boolean,
    ttl?: number,
    callback?: Function,
  ) => {
    const previousConsent = await visitorIdentityService.getCookieConsent();
    if (previousConsent !== hasConsented) {
      await visitorIdentityService.registerCookieConsent({
        hasConsented,
        ttl: ttl || DEFAULT_TTL_S,
      });
      await visitorIdentityService.storeIdentityInCookies(
        hasConsented || shouldStoreCookies,
        ttl || DEFAULT_TTL_S,
      );
      dispatcher.emit(
        hasConsented
          ? 'EngagementVisitorCookieConsentAccepted'
          : 'EngagementVisitorCookieConsentRefused',
        'WebSDK',
        ttl,
      );

      dispatcher.emit('reloadTag');
      callback?.();
    }
  };

  registerSetter(
    'visitor:cookiesConsent',
    debounce((consent, ttl) => {
      const expressedConsent = getExpressedConsentFromStringOrBoolean(consent);
      if (expressedConsent === null) {
        return;
      }
      setVisitorCookiesConsent(expressedConsent, ttl);
    }, 500),
  );

  return (consent: StringOrBoolConsent, ttl?: number, callback?: Function) => {
    deprecate({
      deprecated: 'iAdvize.setVisitorCookiesConsent(consent, ttl)',
      alternative: "iAdvize.set('visitor:cookiesConsent', consent, ttl)",
    });
    const expressedConsent = getExpressedConsentFromStringOrBoolean(consent);
    if (expressedConsent === null) {
      return;
    }
    setVisitorCookiesConsent(expressedConsent, ttl, callback);
  };
};
