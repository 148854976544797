import { APICommand } from '../live/src/storage/typings';
import { delCookie } from './cookieStorage/delCookie';
import { getCookie } from './cookieStorage/getCookie';
import { setCookie } from './cookieStorage/setCookie';

export const cookieStorage = {
  setItem: (key: string, value: string, ttl?: number, domain?: string) => {
    return setCookie({ name: key, value, ttl, domain });
  },
  getItem: (key: string) => {
    return getCookie(key);
  },
  removeItem: (key: string, domain?: string) => {
    return delCookie(key, domain);
  },
};

export const applyCommand = (command: APICommand) => {
  const api = command.kind === 'cookie' ? cookieStorage : sessionStorage;
  switch (command.name) {
    case 'set':
      return api.setItem(
        command.key,
        command.value,
        command.ttl ? parseInt(command.ttl, 10) : undefined,
        command.domain,
      );
    case 'get':
      return api.getItem(command.key);
    case 'del':
      return api.removeItem(command.key, command.domain);
  }
};
