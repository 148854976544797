import { Translations } from '@iadvize/translations-library';
import { GlobalIdz } from '../../../shared/types/globalTypes';
import translationsLoader from './translationsLoader';
import createState from '../../../shared/utils/createState';

const [getHasLoadedTrads, setHasLoadedTrads] = createState(false);
const loadTranslations = async (
  idz: GlobalIdz,
  translationsFromTemplates: Partial<Translations> = {},
): Promise<void> => {
  if (idz.trad && Object.keys(idz.trad).length > 0 && getHasLoadedTrads()) {
    return;
  }
  setHasLoadedTrads(true);
  const translations = await translationsLoader(idz.curlang);
  const overridenTranslations =
    idz.overridenTranslations as Partial<Translations>;
  //_______________________________________________
  idz.trad = {
    ...translations,
    ...overridenTranslations,
    ...translationsFromTemplates,
  };
};

export default loadTranslations;
