import { Translations } from '@iadvize/translations-library';
import { isCorsEnabled, isLocalStorageEnabled } from './launcher.utils';
import {
  startPerformanceMeasure,
  endPerformanceMeasure,
  PerformanceMarks,
} from '../../../lib/performance';
import { buildHttpUrl, buildWsUrl } from '../../entry/buildUrl/getUrls';
import initializeCallbacks from './Callbacks';
import { mergeIntoiAdvizeConfig, getDynamicConfig } from './live.config';
import registerLivechatLoader from './livechatLoader';
import {
  registerPublicMethods,
  registerPublicMethodsTracking,
} from '../../entry/iAdvizeInterface/iAdvizeInterface';
import registerStorage from './storage/register';
import registerVisitorIdentityService from './visitor-identity/register';
import registerTransactionService from './transaction';
import dispatcherFactory from './event-manager';

import translate from './i18n/translationService';

import liveModuleLoader from './loader';
import targetingModuleLoader from '../../targeting/src/dynamicRegister';
import scheduleOnMainJsThread from '../../../lib/scheduleOnMainTread';
import loadGdpr from './loadGdpr';
import { getStaticUrl } from '../../shared/staticUrl';
import getAmd from './amd/amd';
import launchDataPipeline from './launchDataPipeline';
import initChat from './chatInitializer';
import createVisitorService from '../../visitorService';
import spaManagerFactory, { SpaManager } from './spaManager';
import { StaticConfig } from '../../entry/types';
import { VisitorService } from '../../visitorService/types';
import { GlobalIdz } from '../../shared/types/globalTypes';
import createSetVisitorCookiesConsent from './visitor-identity/createSetVisitorCookiesConsent';
import {
  configureSentry,
  getIsSentryLoaded,
} from '../../shared/utils/sentry/sentryLauncher';
import { ExpressedConsent } from '../../shared/types/consent';
import { getUseExplicitCookiesConsent } from '../../entry/explicitCookiesConsent/explicitCookiesConsent';
import { activeTimeSpentOnPageTimer } from '../../entry/visitorState/visitorActivityOnPage';
import { browsingTimeSpentOnPageTimer } from '../../entry/visitorState/browsingTimeSpentOnPageTimer';
import loadTranslations from './i18n/loadTranslations';
import {
  isChatting,
  shouldReengage,
  getIsFromMail,
} from '../../shared/globals';
import { getPublicPropertiesStore } from '../../entry/publicMethods/publicProperties/registerPublicProperties';
import { Vuid } from '../../shared/types/utils';
import { IdzFlags } from '../../shared/types/IdzFlags';
import { getPlatform } from '../../entry/buildUrl/getPlatformDomain';
import { CommonConfig } from '../../entry/visitorContextConfig/types';
import { getClientId } from '../../entry/visitorContextConfig/websiteConfiguration';
import debugLauncher from '../../entry/publicMethods/debug/debugLauncher';
import * as CHAT_STATUS from '../../shared/types/chatStatus';
import { getIsFromLightMode } from '../../entry/visitorContextConfig/executionContext';
import trackEngagementRules from './trackEngagementRules';
import noop from '../../shared/utils/noop';

const amd = getAmd();

type LauncherParams = {
  staticConfig: StaticConfig['config'];
  vuid: string;
  commonConfig: CommonConfig;
  screen?: string;
  previousScreen?: string;
  transactionCalls: GlobalIdz['recordTransaction']['calls'];
  translationsFromTemplates: Partial<Translations>;
  conversationId?: string | null;
};

export default async function launcher({
  staticConfig,
  vuid: originalVuid,
  commonConfig,
  screen,
  previousScreen,
  transactionCalls = [],
  translationsFromTemplates = {},
  conversationId,
}: LauncherParams): Promise<void> {
  /*_______________________________________*/
  if (!isCorsEnabled() || !isLocalStorageEnabled()) {
    return;
  }
  const preloadChunks = async () => {
    //_______________________________________
    __webpack_public_path__ = getStaticUrl();

    //______________________
    [liveModuleLoader.targeting, ...Object.values(targetingModuleLoader)].map(
      (fn) => scheduleOnMainJsThread(fn)(),
    );
  };

  /*_____________________________________________________________________________________________________________________*/
  const checkIsIE11 = () => {
    const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(
      navigator.userAgent,
    );
    if (match) {
      return parseInt(match[2]) === 11; //______________________________
    }

    return false;
  };

  //_____________________________________________________________

  window.iAdvize = {
    ...window.iAdvize,
    define: amd.define,
    require: amd.require,
    undefine: amd.undefine,
    T: translate,
    urls: {
      buildWsUrl,
      buildHttpUrl,
    },
    version: process.env.BUILD_IDENTIFIER!,
    platform: getPlatform(),
    ...commonConfig,
  } as GlobalIdz;

  const { iAdvize } = window;
  preloadChunks();

  const dispatcher = dispatcherFactory(iAdvize);

  mergeIntoiAdvizeConfig({
    operator: {},
    c2coperator: {},
    ...staticConfig,
    vuid: originalVuid as Vuid,
  });

  //______________________________
  //________________________________________________________________________________________

  if (
    window.Prototype &&
    (!window.Prototype.Version || !/^1\.7/.test(window.Prototype.Version)) &&
    new Date(staticConfig.website_dateadded).getTime() > 1563375915929
  ) {
    return;
  }

  const useExplicitCookiesConsent = getUseExplicitCookiesConsent();

  const storage = await registerStorage(
    iAdvize,
    staticConfig?.thirdPartyCookies,
  );

  const publicPropertiesStore = getPublicPropertiesStore();

  const visitorIdentityService = registerVisitorIdentityService(
    iAdvize,
    storage,
    publicPropertiesStore,
  );

  const isIE11 = checkIsIE11();
  //_____________________________________________________
  if (
    window.location.href.indexOf(staticConfig.website_url, 0) === -1 ||
    isIE11
  ) {
    return;
  }

  let clearCallbacksState: (() => void) | null = null;
  let visitorService: VisitorService | null = null;
  let spaManager: SpaManager | null = null;

  const shouldResumeConversation = (flags: Partial<IdzFlags>) =>
    (flags.chat_status_witnessed === `${CHAT_STATUS.CHATTING}` &&
      flags.chat_status === `${CHAT_STATUS.NOT_CHATTING}`) ||
    getIsFromMail();

  //____________________________________________________________________
  iAdvize.useExplicitCookiesConsent = useExplicitCookiesConsent;

  startPerformanceMeasure(PerformanceMarks.LAUNCHER_LOAD_CURRENT_VUID);
  await visitorIdentityService.loadAnonymousVuidInGlobalConfiguration(
    originalVuid,
  );
  endPerformanceMeasure(PerformanceMarks.LAUNCHER_LOAD_CURRENT_VUID);
  const { vuid, ...dynamicConfig } = await getDynamicConfig(conversationId);

  const enableAutoNavigate =
    !dynamicConfig.featureFlags[
      'iadvize.engagement.targeting.disable-auto-navigate'
    ];
  spaManager = spaManagerFactory(dispatcher, enableAutoNavigate);

  endPerformanceMeasure(PerformanceMarks.FETCH_DYNAMIC_CONFIG);

  publicPropertiesStore.dispatch(
    'conversation:id',
    dynamicConfig.flags.conversationId || null,
  );

  /*________________________________________________________________________________________________________________________________________________________________________________*/
  if (shouldResumeConversation(dynamicConfig.flags)) {
    visitorIdentityService.registerCookieConsent({
      hasConsented: true,
    });
  }

  const cookieConsent: ExpressedConsent = useExplicitCookiesConsent
    ? await visitorIdentityService.getCookieConsent()
    : true;

  await visitorIdentityService.storeIdentityInCookies(
    cookieConsent === true ||
      !useExplicitCookiesConsent ||
      shouldResumeConversation(dynamicConfig.flags),
  );

  //_________________________________________________________________
  const transactionService = registerTransactionService(
    iAdvize,
    storage,
    transactionCalls,
  );
  transactionService.recordTransaction();
  transactionService.createTransactionWatcher();

  const { callbacks, clearCallbacksState: _clearCallbacksState } =
    initializeCallbacks(window.iAdvizeCallbacks, dispatcher, storage);
  iAdvize.callbacks = callbacks;
  clearCallbacksState = _clearCallbacksState;

  const anonymousVuid = await visitorIdentityService.getAnonymousVuid();
  if (!anonymousVuid) {
    await visitorIdentityService.setAnonymousVuid(vuid);
  }

  initChat(iAdvize, dynamicConfig.flags);
  const pageLoadedSince = Date.now() - browsingTimeSpentOnPageTimer.time;
  visitorService = createVisitorService(
    dispatcher,
    screen,
    previousScreen,
    pageLoadedSince,
    activeTimeSpentOnPageTimer.time,
  );

  const launchDataPipelinePromise = launchDataPipeline(
    dispatcher,
    publicPropertiesStore,
    cookieConsent,
    !getIsFromLightMode(),
  );

  const setVisitorCookiesConsent = createSetVisitorCookiesConsent(
    visitorIdentityService,
    dispatcher,
    !useExplicitCookiesConsent,
  );

  registerPublicMethodsTracking((method) =>
    dispatcher.emit('visitor.sdk.executed', { type: 'webSDK', method }),
  );

  if (commonConfig.debugModeAvailable) {
    debugLauncher(
      commonConfig.launchdarklyClientKey,
      commonConfig.graphql.url,
      commonConfig.engagement.url,
      commonConfig.language,
    );
  }

  registerPublicMethods({
    navigate: spaManager.navigate,
    setVisitorCookiesConsent,
  });

  publicPropertiesStore.on('visitor:sourceId', (updatedVuid: Vuid | null) => {
    if (updatedVuid) {
      iAdvize.vuid = updatedVuid;
    }
  });

  const shouldLoadLivechatImmediately = isChatting() || shouldReengage();

  const useOldStrophe = window.Prototype !== undefined;
  const loadLivechatIfNotAlreadyLoaded = registerLivechatLoader(
    iAdvize,
    dispatcher,
    dynamicConfig.preferences.mirroring_enabled === '1',
    useOldStrophe,
  );

  let isOrchestratorLoaded = false;

  dispatcher.on('orchestrator.loaded', () => {
    isOrchestratorLoaded = true;
    endPerformanceMeasure(PerformanceMarks.TARGETING_LOAD_ORCHESTRATOR);
  });

  startPerformanceMeasure(PerformanceMarks.LAUNCHER_LOAD_SCRIPTS);

  //_______________________________________________________________
  if (getIsSentryLoaded()) {
    configureSentry({
      clientId: getClientId() || undefined,
      environment: staticConfig.platform,
      featureFlags: dynamicConfig.featureFlags,
      vProf: dynamicConfig.vProf,
      vuid,
      websiteId: staticConfig.website_id,
    });
  }

  loadGdpr();

  if (
    !dynamicConfig.featureFlags[
      'iadvize.conversations.livechat-template-loading-optimization'
    ]
  ) {
    await loadTranslations(iAdvize, translationsFromTemplates);
  }

  launchDataPipelinePromise
    .then(() => trackEngagementRules(dispatcher))
    //________________________________________________________
    .catch(noop);

  const { default: runTargeting } = await liveModuleLoader.targeting();
  runTargeting(clearCallbacksState!, visitorService!, spaManager!);

  endPerformanceMeasure(PerformanceMarks.LAUNCHER_LOAD_SCRIPTS);

  if (!isOrchestratorLoaded) {
    await dispatcher.once('orchestrator.loaded');
  }

  if (shouldLoadLivechatImmediately) {
    loadLivechatIfNotAlreadyLoaded();
  }
}
