type AsyncModuleKey =
  | 'dispatcher'
  | 'mirror'
  | 'storage'
  | 'visitorIdentityService'
  | 'visitorService';

export default function createAMDModules() {
  const definitions: Partial<Record<AsyncModuleKey, Function>> = {};
  const modules: Partial<Record<AsyncModuleKey, unknown>> = {};

  const require = function <T>(moduleKey: AsyncModuleKey): T {
    if (!(moduleKey in modules)) {
      const exports = {};
      const result = definitions[moduleKey]
        ? definitions[moduleKey]!.apply(window.iAdvize, [require, exports])
        : //________________________________________
          console.warn(`${moduleKey} not found.`);
      modules[moduleKey] = result !== undefined ? result : exports;
    }

    return modules[moduleKey] as T;
  };

  //______________________________________________________________
  const define = function (moduleKey: AsyncModuleKey, fn: Function) {
    definitions[moduleKey] = fn;
  };

  const undefine = function (moduleKey: AsyncModuleKey) {
    delete definitions[moduleKey];
    delete modules[moduleKey];
  };

  return {
    define,
    require,
    undefine,
  };
}

export type AMDModules = ReturnType<typeof createAMDModules>;
