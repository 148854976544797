import { loadScripts } from '../../../lib/ScriptLoader';
import {
  startPerformanceMeasure,
  PerformanceMarks,
} from '../../../lib/performance';

import { GlobalIdz } from '../../shared/types/globalTypes';
import { DispatcherWithRegistry } from './event-manager/dispatcherWithRegistry';

let isMirroringLoaded = false;
let isLivechatLoaded = false;

export default (
  idz: GlobalIdz,
  dispatcher: DispatcherWithRegistry,
  isMirroringEnabled: boolean,
  useOldStrophe: boolean,
) => {
  /*__________________________________________________________________________________________________________________*/
  const loadLivechat = async () => {
    if (isMirroringEnabled && !isMirroringLoaded) {
      isMirroringLoaded = true;
      await new Promise<void>((resolve) => {
        loadScripts(
          [
            {
              url: `https://${idz.mirrorHost}/projector/projector.min.js`,
              shouldWait: true,
              shouldContinueOnError: true,
            },
          ],
          resolve,
        );
      });
    }

    const { default: initLivechat } = await import(
      /*________________________________________*/
      '../../livechat/src/initLivechat'
    );
    await initLivechat(useOldStrophe);
  };

  const loadLivechatIfNotAlreadyLoaded = async (): Promise<void> => {
    if (isLivechatLoaded) {
      return;
    }
    isLivechatLoaded = true;
    startPerformanceMeasure(PerformanceMarks.CHATBOX_DISPLAY);
    await loadLivechat();
  };

  dispatcher.on('engagement.showChatboxButtonClickedBeforeBundle', (event) => {
    loadLivechatIfNotAlreadyLoaded().then(() =>
      dispatcher.emit('engagement.showChatboxButtonClicked', event),
    );
  });

  dispatcher.on('engagement.showCallButtonClickedBeforeBundle', (event) => {
    loadLivechatIfNotAlreadyLoaded().then(() =>
      dispatcher.emit('engagement.showCallButtonClicked', event),
    );
  });

  dispatcher.on('engagement.showMessageNotificationBeforeBundle', (event) => {
    loadLivechatIfNotAlreadyLoaded().then(() => {
      dispatcher.emit('engagement.showMessageNotification', event);
    });
  });

  dispatcher.on('engagement.showChatboxNotificationBeforeBundle', (event) => {
    loadLivechatIfNotAlreadyLoaded().then(() =>
      dispatcher.emit('engagement.showChatboxNotification', event),
    );
  });

  dispatcher.on('conversations.visio.requestedBeforeBundle', (event) => {
    loadLivechatIfNotAlreadyLoaded().then(() =>
      dispatcher.emit('conversations.visio.requested', event),
    );
  });

  dispatcher.on('tag.livechat.start', loadLivechatIfNotAlreadyLoaded);

  dispatcher.on('tag.livechat.stop', () => {
    isLivechatLoaded = false;
  });

  return loadLivechatIfNotAlreadyLoaded;
};
