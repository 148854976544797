export default async function fetchWithTimeout(
  resource: string,
  timeout: number,
  options?: RequestInit,
) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}
