/*______________________________________*/
/*______________________________*/

import { IdzFlags } from '../../shared/types/IdzFlags';
import { GlobalIdz } from '../../shared/types/globalTypes';
import { ChatStatus } from '../../shared/types/chatStatus';

/*_________________________________________________________________________________________________________________*/
export default (idz: GlobalIdz, flags: Partial<IdzFlags>) => {
  idz.chat = {
    ...idz.chat,
    history_id: flags?.history_id || 0,
    status: flags?.chat_status && (Number(flags.chat_status) as ChatStatus),
  };
};
