import { getUseExplicitCookiesConsent } from '../../../../entry/explicitCookiesConsent/explicitCookiesConsent';
import retrier from '../../../../shared/utils/retrier';

const translations = {
  fr: 'Encadré sans contenu',
  en: 'Box without content',
  es: 'iframe sin contenido',
  de: 'iframe ohne Inhalt',
} as const;

type KnownTranslations = 'fr' | 'en' | 'es' | 'de';
const isKnownKey = (t: string): t is KnownTranslations =>
  Object.keys(translations).indexOf(t) !== -1;
const getTranslationTitle = (t: string) =>
  isKnownKey(t) ? translations[t] : translations.en;
const useExplicitCookiesConsent = getUseExplicitCookiesConsent();

const commonKeys = ['jwtIdentity', 'vuid'] as const;
const consentKey = 'consent';
const thirdPartyKeys = useExplicitCookiesConsent
  ? commonKeys
  : ([...commonKeys, consentKey] as const);

export type ThirdPartyKey = typeof thirdPartyKeys[number];

export const isThirdPartyKey = (key: string): key is ThirdPartyKey =>
  (thirdPartyKeys as unknown as ThirdPartyKey[]).includes(key as ThirdPartyKey);

//__________________________
const createIframe = (iframeUrl: string, document: Document, title: string) => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('aria-hidden', 'true');
  const iframeStyle = iframe.style;
  iframeStyle.display = 'none';
  iframeStyle.width = '1px';
  iframeStyle.height = '1px';
  iframeStyle.border = '0';
  iframe.title = title;
  iframe.src = iframeUrl;
  return iframe;
};

/*_________________________________________________________________________*/
export const initIFrame = async (
  iframeUrl: string,
  document: Document,
  curlang: string,
) => {
  const title = getTranslationTitle(curlang);
  const iframe = createIframe(iframeUrl, document, title);
  await retrier(
    () =>
      new Promise((resolve, reject) => {
        if (document.body) {
          resolve(true);
        } else {
          reject(
            new Error(
              'Storage: Cannot add iframe. The document body is not loaded.',
            ),
          );
        }
      }),
  );
  document.body.appendChild(iframe);
  return iframe;
};
