/*______________________________*/
import { getIdz, getEventManager } from '../shared/globals';
import getJSONStore from '../shared/JSONStore';

import visitorState, * as visitorStateService from './visitorState';
import * as visitorApiClient from './visitorApiClient';
import * as insiteCustomDataObserver from './inSiteCustomDataObserver';
import Store from '../shared/Store';
import getDeviceInformation from '../shared/DeviceInformationExtractor';
import { sanitizeHashUrl } from '../shared/urlHelpers';
import {
  getIsSentryLoaded,
  sentryCatchCustomErrors,
} from '../shared/utils/sentry/sentryLauncher';

const visitorAttributeFlagPrefix = 'va_';

/*_____________________________________________________________________________________________*/
const setAndSaveCustomData = (key, value) => {
  visitorStateService.setCustomData(key, value);

  const flagName = visitorAttributeFlagPrefix + key;
  getJSONStore().saveData({ [flagName]: value });
};

/*____________________________________________________________________*/
const saveConversationCustomData = (conversationId) =>
  visitorApiClient.saveConversationCustomData(
    conversationId,
    visitorStateService.getAllCustomData(),
  );

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
const loadCrossPagesStateIntoVisitorState = function (flags) {
  if (!flags) {
    return;
  }

  visitorState.history_id = +flags.history_id;
  visitorState.sessionStartedSince =
    parseInt(flags.proactif_timeElapsed, 10) * 1000;

  //___________________________________________________
  Object.entries(flags).forEach(([flag, flagValue]) => {
    if (flag.startsWith(visitorAttributeFlagPrefix)) {
      const flagName = flag.substring(visitorAttributeFlagPrefix.length);

      visitorStateService.setCustomData(flagName, flagValue);
    }
  });
};

const loadOldVisitorProfileIntoVisitorState = function (profile = {}) {
  //____________________________________________
  Object.entries(profile).forEach(([key, value]) => {
    visitorState[key] = value;
  });
};

const loadDeviceInfoIntoVisitorState = (deviceInformation) => {
  //________________________
  visitorState.winWidth = window.innerWidth;
  visitorState.winHeight = window.innerHeight;
  visitorState.browserInfo = `${deviceInformation.browser} ${deviceInformation.version}`;
  visitorState.deviceInfo = deviceInformation.OS;
  visitorState.userAgent = navigator.userAgent;
  visitorState.deviceType = deviceInformation.deviceType;
};

const loadPageInfoIntoVisitorState = (
  newScreen,
  previousScreen = document.referrer,
) => {
  //_____________________________________________
  //__________________________________________________________________
  //___________________________________________________________________
  //__________________________________________________________________

  visitorState.actualURI = document.location.href;

  visitorState.referrer_lastPage = previousScreen;

  //___________________________________________________
  if (newScreen) {
    //__________________________________________________
    visitorState.referrer_lastPage =
      visitorState.actualScreen || previousScreen;
    visitorState.actualScreen = newScreen;
  } else {
    try {
      visitorState.actualScreen = sanitizeHashUrl(visitorState.actualURI);
    } catch (e) {
      throw new Error(
        `Cannot update visitor state actualScreen. Visitor current URI is not valid: ${visitorState.actualURI}`,
      );
    }
  }
};

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
const loadJsCustomDataIntoVisitorState = (jsCustomDataInput) => {
  if (typeof jsCustomDataInput === 'object') {
    Object.entries(jsCustomDataInput).forEach(([key, value]) => {
      if (value != null) {
        visitorStateService.setCustomData(key, value);
      }
    });

    //_______________________________________________________________________________________
    //__________________________________________________________________________
    const ue = jsCustomDataInput.cust_email || jsCustomDataInput.user_email;
    if (ue) Store.set('custEmail', ue);
    if (jsCustomDataInput.custProfile) {
      Store.set('custProfile', jsCustomDataInput.custProfile);
    }

    //_______________________________
    if (jsCustomDataInput.extID) {
      getJSONStore().saveData({ extID: jsCustomDataInput.extID });
    }
  }
};

const reloadVisitorInfos = () => {
  const idz = getIdz();
  insiteCustomDataObserver.unsubscribeAll();
  visitorStateService.deleteAllCustomData();

  loadOldVisitorProfileIntoVisitorState(idz.vProf);
  loadCrossPagesStateIntoVisitorState(idz.flags);

  loadJsCustomDataIntoVisitorState(window.idzCustomData);

  insiteCustomDataObserver.loadStateIntoVisitorState();
  insiteCustomDataObserver.observeInSiteCustomData(() =>
    getEventManager().emit('targeting.run'),
  );
};

const maintainVisitorStateSideEffects = (idz, withIdentityChange) => {
  reloadVisitorInfos();
  if (withIdentityChange) {
    const dispatcher = getEventManager();
    dispatcher.emit('auth.endSwitch');
    if (Number(idz.flags.chat_status) === 2) {
      dispatcher.emit('targeting.pauseAndHide');
    }
    dispatcher.emit('reloadTag');
  }
};

const maintainVisitorState = async (withIdentityChange = false) => {
  const idz = getIdz();
  try {
    const { flags, profile, operator } =
      await visitorApiClient.setVisitorState();
    idz.flags = flags;
    idz.vProf = profile;
    if (operator) {
      idz.operator = operator;
    }
    idz.chat.status = flags.chat_status && Number(flags.chat_status);
    idz.chat.history_id = (flags.history_id && Number(flags.history_id)) || 0;
  } catch (error) {
    if (getIsSentryLoaded()) {
      sentryCatchCustomErrors(error);
    }
  } finally {
    maintainVisitorStateSideEffects(idz, withIdentityChange);
  }
};

/*____________________________________________________________________________________________________________________________________________________________________________________________________________________*/
const updateVisitorStateToScreenChange = (
  screen,
  previousScreen,
  pageLoadedSince,
  activityOnPage,
) => {
  const idz = getIdz();
  //___________________________________________________
  visitorState.lang = idz.curlang;

  const now = new Date().getTime();
  if (pageLoadedSince) {
    visitorState.pageLoadedSince = pageLoadedSince;
  } else {
    visitorState.pageLoadedSince = now;
  }
  if (activityOnPage) {
    //____________________
    visitorState.activityOnPage = activityOnPage / 1000;
  } else {
    visitorState.activeTimeSpentOnPageTimer.reset();
    visitorState.activeTimeSpentOnPageTimer.start();
    visitorState.activityOnPage = 0;
  }
  //_______________________________________________________________________
  visitorState.vuid = idz.vuid;

  //____________________________
  loadPageInfoIntoVisitorState(screen, previousScreen);
  loadDeviceInfoIntoVisitorState(getDeviceInformation());
};

/*________________________________________________________________________*/
const init = (
  dispatcher,
  screen,
  previousScreen,
  pageLoadedSince,
  activityOnPage,
) => {
  getIdz().vStats = visitorState;
  updateVisitorStateToScreenChange(
    screen,
    previousScreen,
    pageLoadedSince,
    activityOnPage,
  );
  reloadVisitorInfos();

  const destroy = () => {
    insiteCustomDataObserver.unsubscribeAll();
    visitorStateService.deleteAllCustomData();
  };

  const service = {
    ...visitorStateService,
    setAndSaveCustomData,
    saveConversationCustomData,
    maintainVisitorState,
    updateVisitorStateToScreenChange,

    destroy,
  };

  getIdz().define('visitorService', () => service);

  dispatcher.on('auth.beginSwitch', () => {
    maintainVisitorState(true);
  });
  dispatcher.on('auth.authenticatedSessionFinished', () => {
    maintainVisitorState(false);
  });

  return service;
};

export default init;
