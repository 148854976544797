import { GlobalIdz } from '../../../shared/types/globalTypes';
import CustomEventEmitter from '../../../shared/utils/CustomEventEmitter';
import {
  DispatcherWithRegistry,
  dispatcherWithRegistryFactory,
} from './dispatcherWithRegistry';
import { AllEvents } from './types';

export default (idz: GlobalIdz): DispatcherWithRegistry => {
  const dispatcher = CustomEventEmitter<AllEvents>('dispatcher');
  idz.define('dispatcher', () => dispatcher);
  return dispatcherWithRegistryFactory('dispatcher');
};
