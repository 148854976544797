import noop from '../modules/shared/utils/noop';

type ScriptObject = {
  url: string;
  shouldWait?: boolean;
  shouldContinueOnError?: boolean;
};

const createTag = (
  script: string,
  callback: () => void = noop,
  shouldContinueOnError?: boolean,
) => {
  const tag = document.createElement('script');
  tag.defer = true;
  tag.onload = callback;
  tag.src = script;
  if (shouldContinueOnError) {
    tag.onerror = callback;
  }

  return tag;
};

const insertTag = (tag: HTMLScriptElement) => {
  document.head.appendChild(tag);
};

export const loadScripts = (
  scripts: ScriptObject[],
  finalCallback?: () => void,
) => {
  if (scripts.length === 0) {
    finalCallback?.();
  }

  const shouldWaitIndex = scripts.findIndex((a) => a.shouldWait);
  let partOne = scripts.slice(0, shouldWaitIndex + 1);
  const partTwo = scripts.slice(shouldWaitIndex + 1);

  //__________________________________________________________
  if (partOne.length === 0) {
    partOne = partTwo;
  }

  partOne.forEach(({ url, shouldWait, shouldContinueOnError }, index) => {
    if (shouldWait) {
      //__________________________________________________________________________
      if (partTwo.length === 0) {
        insertTag(createTag(url, finalCallback, shouldContinueOnError));
        return;
      }

      insertTag(
        createTag(
          url,
          () => loadScripts(partTwo, finalCallback),
          shouldContinueOnError,
        ),
      );
      return;
    }

    const callback = index === partOne.length - 1 ? finalCallback : noop;
    insertTag(createTag(url, callback, shouldContinueOnError));
  });
};
