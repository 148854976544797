/*_________________________________________*/
import noop from '../../../shared/utils/noop';
import { StorageAPI } from '../storage/typings';

import {
  UTM_CAMPAIGN_STORAGE_KEY,
  TRANSACTION_COOKIES_TTL,
} from './cookiesSettings';

export default (storage: StorageAPI) => {
  /*_________________________________________________________*/
  function parseQueryString(queryString: string): Record<string, string> {
    const params: Record<string, string> = {};

    //_______________________________
    const queries = queryString.split('&');

    //________________________________________________
    for (let i = 0, l = queries.length; i < l; i += 1) {
      const temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }

    return params;
  }

  /*__________________________________________________*/
  function recoverUtmCampaign(): string | null {
    const queryParameters = parseQueryString(
      document.location.search.substring(1),
    );
    if (
      queryParameters.utm_roi &&
      (queryParameters.utm_roi === 'iAdvize' ||
        queryParameters.utm_roi === 'idz') &&
      queryParameters.utm_campaign
    ) {
      return queryParameters.utm_campaign;
    }
    return null;
  }

  /*_______________________________________________________________________________________*/
  function storeUtmCampaign(callback = noop) {
    const utmCampaign = recoverUtmCampaign();

    if (!utmCampaign) {
      callback();
    } else {
      storage.set(
        UTM_CAMPAIGN_STORAGE_KEY,
        { value: `${utmCampaign}`, ttl: TRANSACTION_COOKIES_TTL },
        callback,
        'cookie',
      );
    }
  }

  /*__________________________________________________*/
  function getUtmCampaign(callback: (value: string | null) => void = noop) {
    return storage.get(UTM_CAMPAIGN_STORAGE_KEY, callback, 'cookie');
  }

  return {
    storeUtmCampaign,
    getUtmCampaign,
  };
};
