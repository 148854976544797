import { getIdz } from './globals';
import debounce from './utils/debounce';

type JSONStoreType = {
  saveData: (data: JSONStoreData) => Promise<void>;
  save: (data: JSONStoreData) => Promise<void>;
};
type JSONStoreData = Record<string, unknown>;

/*________________________________________________________________________________________________________________________*/
function buildJsonStoreQueryString(
  data: JSONStoreData,
  context: { vuid: string; website_id: number },
) {
  return [
    `sid=${context.website_id}`,
    `u=${context.vuid}`,
    `data=${encodeURIComponent(JSON.stringify(data))}`,
  ].join('&');
}

let JSONStore: JSONStoreType | null = null;

const initializeJSONStore = () => {
  if (JSONStore) return JSONStore;
  const idz = getIdz();
  let tempData: JSONStoreData = {};

  /*_______________________________________________________________________________________________________________________________________________________________________________*/
  const save = async (data: JSONStoreData) => {
    //________________________________________________________________________________
    let dataToSend = data;

    if (!dataToSend) {
      dataToSend = tempData;
      tempData = {};
    }

    const url = `${idz.chaturl}rpc/jsonstore.php?${buildJsonStoreQueryString(
      dataToSend,
      idz,
    )}`;

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(
        `JSONStore save error. ${
          response.statusText ?? `status: ${response.statusText}`
        }`,
      );
    }
  };

  /*_______________________________*/
  const debouncedSave = debounce(save, 1600);

  /*__________________________________________________________________*/
  const saveData = function (data: JSONStoreData) {
    //_________________________________________
    Object.entries(data).forEach(([key, value]) => {
      tempData[key] = value;
    });

    return debouncedSave(tempData);
  };

  JSONStore = {
    saveData,
    save,
  };

  return JSONStore;
};

export default initializeJSONStore;
