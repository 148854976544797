import createSocialCampaign from './socialCampaign';
import createRetryStrategy from './retryStrategy';
import { InvalidTransactionError } from './errors';
import { StorageAPI } from '../storage/typings';
import { VisitorIdentityService } from '../visitor-identity/VisitorIdentityService';
import { GlobalIdz } from '../../../shared/types/globalTypes';
import { Transaction } from './types';
import noop from '../../../shared/utils/noop';

type LegacyTransaction = typeof window.idzTrans;

/*_______________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export default (
  storage: StorageAPI,
  idz: GlobalIdz,
  transactionCalls: GlobalIdz['recordTransaction']['calls'] = [],
) => {
  let transactionRegistered = false;

  const socialCampaign = createSocialCampaign(storage);
  socialCampaign.storeUtmCampaign();

  const visitorIdentityService = idz.require<VisitorIdentityService>(
    'visitorIdentityService',
  ) as VisitorIdentityService;

  /*_____________________________________________________________*/
  const makeTransactionRequest = (
    transaction: Transaction,
    callback: Function,
  ) => {
    const {
      id,
      //_______________________
      amount,
      socialUtmCampaign,
      transactionDate,
    } = transaction;

    const xhr = new XMLHttpRequest();
    xhr.open(
      'POST',
      `${idz.engagement.url}/conversions?platform=${idz.platform}`,
    );
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        if (this.status === 200) {
          callback();
        } else if (this.status === 400) {
          callback(
            new InvalidTransactionError(
              `Transaction was malformed: ${xhr.responseText}`,
            ),
          );
        } else {
          callback(new Error('An unexpected server error occurred'));
        }
      }
    };

    visitorIdentityService.getDeviceId().then((deviceId) => {
      xhr.send(
        JSON.stringify({
          websiteId: parseInt(idz.website_id as unknown as string, 10),
          platformId: idz.platform,
          sourceVisitorId: idz.vuid,
          deviceId,
          eventName: 'engagement.transaction.concluded',
          source: 'ONSITE',
          externalConversionId: id,
          visitorDate: transactionDate,
          amount,
          socialUtmCampaign,
        }),
      );
    });
  };

  const retryStrategy = createRetryStrategy(storage, makeTransactionRequest);
  retryStrategy.tryRecoveringTransaction();

  /*_________________________________________________________*/
  const sendTransaction = (transaction: Transaction, callback = noop) =>
    socialCampaign.getUtmCampaign((socialUtmCampaign) => {
      const { id, amount } = transaction;
      const transactionDate = new Date().toISOString();

      transactionRegistered = true;

      if (id && amount >= 0) {
        retryStrategy.storeAndSendTransactionWithRetries(
          {
            ...transaction,
            transactionDate,
            socialUtmCampaign,
          },
          callback,
        );
      }
    });

  const recordTransactionWithoutPayload = (callback = noop) => {
    if (
      typeof window.idzTrans !== 'undefined' &&
      !/(ip-label|WatchMouse)/.test(navigator.userAgent)
    ) {
      const { cartAmount, tID } = window.idzTrans;

      const transaction = {
        id: `${tID}`,
        amount:
          typeof cartAmount === 'number'
            ? cartAmount
            : parseFloat(cartAmount) || 0,
      };

      sendTransaction(transaction, callback);
    }
  };
  /*_______________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const recordTransactionWithPayload = (
    transaction: Transaction,
    callback = noop,
  ) => {
    const { id, amount } = transaction || {};
    if (
      (typeof id !== 'string' || typeof amount !== 'number') &&
      window.idzTrans !== undefined
    ) {
      recordTransactionWithoutPayload(callback);
      return;
    }

    if (typeof id !== 'string') {
      //________________________________________
      console.error(
        'the "id" field of transaction needs to be a unique string',
      );
    }
    if (typeof amount !== 'number' || amount < 0) {
      //________________________________________
      console.error(
        'the "amount" field of transaction needs to be a positive number',
      );
    }
    sendTransaction({ id: `${id}`, amount: +amount }, callback);
  };

  /*_________________________________________________________________________________________________________________________________________________________________________________________________*/
  const createTransactionWatcher = () => {
    const intervalId = setInterval(() => {
      if (transactionRegistered) {
        clearInterval(intervalId);
        return;
      }

      recordTransactionWithoutPayload();
    }, 666);
  };

  const recordTransaction = (
    transaction?: Transaction | LegacyTransaction,
    callback = noop,
  ) => {
    const formattedTransaction: Transaction = {
      id:
        (transaction as Transaction)?.id ??
        String((transaction as LegacyTransaction)?.tID),
      amount:
        (transaction as Transaction)?.amount ??
        Number((transaction as LegacyTransaction)?.cartAmount),
    };
    const transactionReturn = transaction
      ? recordTransactionWithPayload(formattedTransaction, callback)
      : recordTransactionWithoutPayload(callback);

    if (transaction) {
      recordTransaction.calls.push({
        id: formattedTransaction.id,
        amount: formattedTransaction.amount,
      });
    }

    return transactionReturn;
  };

  recordTransaction.calls = transactionCalls;

  return {
    recordTransaction,
    createTransactionWatcher,
  };
};
