import { selectOpt, elementTextOrVal } from './domHelpers';

const executeNowAndEveryInterval = (callback: Function, interval: number) => {
  let timeoutId: number;
  const tick = () => {
    callback();
    timeoutId = window.setTimeout(tick, interval);
  };
  tick();

  return () => clearTimeout(timeoutId);
};

type Subscriptions = {
  cssSelector: string;
  onChange: Function;
};
/*___________________________________________________________________________________________________________________________________________________________________________________________________________*/
const createIntervalBasedDomObserver = (
  subscriptions: Subscriptions[],
  interval: number,
) => {
  const checkForChangeFactory = ({ cssSelector, onChange }: Subscriptions) => {
    let currentValue = elementTextOrVal(selectOpt(cssSelector));

    return () => {
      const newValue = elementTextOrVal(selectOpt(cssSelector));
      if (currentValue !== newValue) {
        currentValue = newValue;
        onChange(newValue);
      }
    };
  };

  const changeChecks = subscriptions.map(checkForChangeFactory);
  const checkForChanges = () =>
    changeChecks.forEach((checkForChange) => checkForChange());

  return {
    destroy: executeNowAndEveryInterval(checkForChanges, interval),
  };
};

export default createIntervalBasedDomObserver;
