/*___________________________________________________________________________________________________________________________________*/

import { buildHttpUrl } from '../../entry/buildUrl/getUrls';
import { getTemplateName } from '../../entry/visitorContextConfig/websiteConfiguration';
import { DynamicConfig } from '../../shared/types/dynamicConfig';
import { GlobalIdz } from '../../shared/types/globalTypes';

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/

const getDynamicConfigAddr = (vuid: string, conversationId?: string | null) => {
  const { iAdvize } = window;
  const sid = iAdvize.website_id;
  const dep = iAdvize.forceDep;
  const tpl = getTemplateName();
  const lang = iAdvize.curlang;

  let fragment = `rpc/live.2.php?sid=${sid}&dep=${dep}&tpl=${tpl}&lang=${lang}&version=${process
    .env.BUILD_IDENTIFIER!}`;

  if (vuid) {
    fragment = `${fragment}&vuid=${vuid}`;
  }

  if (conversationId) {
    fragment = `${fragment}&conversationId=${conversationId}`;
  }
  return buildHttpUrl(fragment);
};

export const mergeIntoiAdvizeConfig = (config: Partial<GlobalIdz>) => {
  Object.keys(config).forEach((key) => {
    //_________________________________________
    (window.iAdvize as GlobalIdz)[key] = config[key as keyof GlobalIdz];
  });
};

export async function getDynamicConfig(
  oConversationId?: string | null,
): Promise<DynamicConfig> {
  const response = await fetch(
    getDynamicConfigAddr(window.iAdvize.vuid, oConversationId),
  );
  const { config: dynamicConfig, operator = {} } = await response.json();
  mergeIntoiAdvizeConfig({ ...dynamicConfig, operator });
  return { ...dynamicConfig, vuid: window.iAdvize.vuid };
}
