export const primitiveTypes = {
  STRING: 'STRING',
  INTEGER: 'INTEGER',
  FLOAT: 'FLOAT',
  BOOL: 'BOOL',
};

export const inSiteTypes = {
  STRING: 'HTML',
  INTEGER: 'IHTML',
  FLOAT: 'FHTML',
};

export const inSiteAggregationTypes = {
  CURRENT: 'current',
  PREVIOUS_PAGE: 'prev',
  MIN: 'min',
  MAX: 'max',
  COUNT: 'count',
};
