import { GlobalIdz } from '../../../shared/types/globalTypes';
import { StorageAPI } from '../storage/typings';
import createVisitorIdentityService from './VisitorIdentityService';
import { DispatcherWithRegistry } from '../event-manager/dispatcherWithRegistry';
import { getEventManager } from '../../../shared/globals';
import { PublicPropertiesStore } from '../../../shared/types/publicMethods';

export default (
  idz: GlobalIdz,
  storageClient: StorageAPI,
  publicPropertiesStore: PublicPropertiesStore,
) => {
  const dispatcher = getEventManager() as DispatcherWithRegistry;
  const { isDeviceIdEnabled } = idz;

  const visitorIdentityService = createVisitorIdentityService(
    idz.secure_url,
    storageClient,
    dispatcher,
    publicPropertiesStore,
    isDeviceIdEnabled,
    idz.firstPartyCookieEnabled,
  );

  idz.define('visitorIdentityService', () => visitorIdentityService);

  return visitorIdentityService;
};
