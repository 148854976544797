import {
  authenticationTypeEnum,
  JwtIdentity,
} from '../../../shared/types/visitorIdentity';
import { StorageAPI } from '../storage/typings';
import createVisitorIdentityStorage from './VisitorIdentityStorage';
import { DispatcherWithRegistry } from '../event-manager/dispatcherWithRegistry';
import { ExpressedConsent } from '../../../shared/types/consent';
import { PublicPropertiesStore } from '../../../shared/types/publicMethods';
import { Vuid } from '../../../shared/types/utils';

export type VisitorIdentityService = {
  isIdentityInconsistent: (jwtIdentity: JwtIdentity | null) => boolean;
  extendAnonymousVuid: () => Promise<void>;
  registerCookieConsent: (consent: {
    hasConsented: boolean;
    consentSource?: 'chatbox' | 'WebSDK';
    ttl?: number;
  }) => Promise<void>;
  getCookieConsent: () => Promise<ExpressedConsent>;
  getIsAcceptedCookieConsentSet: () => Promise<boolean>;
  getAnonymousVuid: () => Promise<string | null>;
  setAnonymousVuid: (vuid: string) => Promise<void>;
  getJwtIdentity: (options?: {
    shouldCheckConsistency?: boolean;
  }) => Promise<JwtIdentity | null>;
  setJwtIdentity: (jwtIdentity: JwtIdentity) => Promise<void>;
  removeJwtIdentity: () => Promise<void>;
  loadCurrentVuidInGlobalConfiguration: () => Promise<void>;
  loadAnonymousVuidInGlobalConfiguration: (
    existingVuid: string | null,
  ) => Promise<void>;
  isAuthenticated: () => Promise<boolean>;
  isJwtIdentityStillValid: (jwtIdentity: JwtIdentity | null) => boolean;
  storeIdentityInCookies: (
    shouldStoreInCookie: boolean,
    ttl?: number,
  ) => Promise<void>;
  getDeviceId: () => Promise<string>;
};

const oneMinuteInMillis = 60000;

export const isJwtIdentityStillValid = (
  jwtIdentity: JwtIdentity | null,
): jwtIdentity is JwtIdentity =>
  !!jwtIdentity &&
  jwtIdentity.localExpireAt > new Date().valueOf() + oneMinuteInMillis;

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export default (
  url: string,
  storage: StorageAPI,
  dispatcher: DispatcherWithRegistry,
  publicPropertiesStore: PublicPropertiesStore,
  isDeviceIdEnabled: boolean,
  isFirstPartyStorage: boolean,
): VisitorIdentityService => {
  const visitorIdentityStorage = createVisitorIdentityStorage(
    url,
    storage,
    publicPropertiesStore,
    isDeviceIdEnabled,
    isFirstPartyStorage,
  );

  /*___________________________________________________________________________________________________________________________________________________________________________________________*/
  let jwtIdentityMemoryState: JwtIdentity | null = null;

  const loadVuidInGlobalConfiguration = (vuid: string | null) => {
    if (vuid) {
      publicPropertiesStore.dispatch('visitor:sourceId', vuid as Vuid);
    }
  };

  /*______________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const loadCurrentVuidInGlobalConfiguration = async () => {
    const jwtIdentity = await visitorIdentityStorage.getJwtIdentity();
    jwtIdentityMemoryState = jwtIdentity;

    if (isJwtIdentityStillValid(jwtIdentity)) {
      loadVuidInGlobalConfiguration(jwtIdentity.vuid);
      return;
    }

    const anonymousVuid = await visitorIdentityStorage.getAnonymousVuid();
    loadVuidInGlobalConfiguration(anonymousVuid);
  };

  const loadAnonymousVuidInGlobalConfiguration = async (
    existingVuid: string | null,
  ): Promise<void> => {
    const vuid =
      existingVuid || (await visitorIdentityStorage.getAnonymousVuid());
    loadVuidInGlobalConfiguration(vuid);
  };

  /*________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const areIdentitiesInconsistent = (
    jwtIdentity1: JwtIdentity | null,
    jwtIdentity2: JwtIdentity | null,
  ) =>
    (jwtIdentity1 && jwtIdentity1.vuid) !== (jwtIdentity2 && jwtIdentity2.vuid);

  const isIdentityInconsistent = (jwtIdentity: JwtIdentity | null): boolean =>
    areIdentitiesInconsistent(jwtIdentity, jwtIdentityMemoryState);

  const getJwtIdentity = async (
    options: { shouldCheckConsistency?: boolean } = {
      shouldCheckConsistency: true,
    },
  ): Promise<JwtIdentity | null> => {
    const jwtIdentity = await visitorIdentityStorage.getJwtIdentity();
    if (options.shouldCheckConsistency && isIdentityInconsistent(jwtIdentity)) {
      await loadCurrentVuidInGlobalConfiguration();
      dispatcher.emit('reloadTag');
    }

    return jwtIdentity;
  };

  /*______________________________________________________________________________________________________________________________________*/
  const setJwtIdentity = async (jwtIdentity: JwtIdentity) => {
    if (!isJwtIdentityStillValid(jwtIdentity)) {
      return;
    }

    await visitorIdentityStorage.setJwtIdentity(jwtIdentity);
    await loadCurrentVuidInGlobalConfiguration();
  };

  /*__________________________________________________________________________________________________________________________________________*/
  const removeJwtIdentity = async () => {
    await visitorIdentityStorage.removeJwtIdentity();
    await loadCurrentVuidInGlobalConfiguration();
  };

  const setAnonymousVuid = async (vuid: string) => {
    await visitorIdentityStorage.setAnonymousVuid(vuid);
    await loadCurrentVuidInGlobalConfiguration();
  };

  const isAuthenticated = async () => {
    const jwtIdentity = await getJwtIdentity();
    return (
      jwtIdentity !== null &&
      jwtIdentity.type !== authenticationTypeEnum.ANONYMOUS
    );
  };

  const registerCookieConsent = (consent: {
    hasConsented: boolean;
    consentSource?: 'chatbox' | 'WebSDK';
    ttl?: number;
  }) => {
    return new Promise<void>((resolve) => {
      visitorIdentityStorage.registerCookieConsent(consent, () => {
        if (consent.consentSource) {
          if (consent.hasConsented) {
            dispatcher.emit(
              'EngagementVisitorCookieConsentAccepted',
              consent.consentSource,
              consent.ttl,
            );
          } else {
            dispatcher.emit(
              'EngagementVisitorCookieConsentRefused',
              consent.consentSource,
            );
          }
        }

        resolve();
      });
    });
  };

  return {
    getAnonymousVuid: visitorIdentityStorage.getAnonymousVuid,
    setAnonymousVuid,
    getCookieConsent: visitorIdentityStorage.getCookieConsent,
    registerCookieConsent,
    getIsAcceptedCookieConsentSet:
      visitorIdentityStorage.getIsAcceptedCookieConsentSet,
    storeIdentityInCookies: visitorIdentityStorage.storeIdentityInCookies,
    extendAnonymousVuid: visitorIdentityStorage.extendAnonymousVuid,
    getJwtIdentity,
    setJwtIdentity,
    removeJwtIdentity,
    loadCurrentVuidInGlobalConfiguration,
    isAuthenticated,
    isJwtIdentityStillValid,
    loadAnonymousVuidInGlobalConfiguration,
    isIdentityInconsistent,
    getDeviceId: visitorIdentityStorage.getDeviceId,
  };
};
