import flow from '../../shared/utils/flow';
import noop from '../../shared/utils/noop';
import { authenticationTypeEnum } from '../../shared/types/visitorIdentity';
import { DispatcherWithRegistry } from './event-manager/dispatcherWithRegistry';
import { IAdvizeCallbacks } from '../../shared/types/callbacks';
import { StorageAPI } from './storage/typings';
import deprecate from '../../shared/utils/deprecate';
import get from '../../entry/publicMethods/get/get';
import {
  CalledMethods,
  getInMemoryCalledMethods,
  setInMemoryCalledMethods,
} from '../../entry/iAdvizeInterface/iAdvizeInterface';

export default (
  iAdvizeCallbacks: Partial<IAdvizeCallbacks>,
  dispatcher: DispatcherWithRegistry,
  storage: StorageAPI,
) => {
  /*________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  //_____________________________________________
  const callbackDefaults: IAdvizeCallbacks = {
    onScriptLoaded: noop,
    onInvitationDisplayed: noop,
    onChatDisplayed: noop,
    onChatHidden: noop,
    onChatButtonDisplayed: noop,
    onCallButtonDisplayed: noop,
    onVideoButtonDisplayed: noop,
    onChatStarted: noop,
    onChatEnded: noop,
    onCallStarted: noop,
    onCallEnded: noop,
    onStatusChanged: noop,
    onCallStatusChanged: noop,
    onRuleStarted: noop,
    onMessageReceived: noop,
    onMessageSent: noop,
    onAlertReceived: noop,
    onOperatorWriting: noop,
    onOperatorStopWriting: noop,
    onVisitorWriting: noop,
    onVisitorStopWriting: noop,
    onOfferReceived: noop,
    onLinkReceived: noop,
    onCobrowsingRequested: noop,
    onCobrowsingAccepted: noop,
    onCobrowsingRefused: noop,
    onCobrowsingStopped: noop,
    onSatisfactionDisplayed: noop,
    onSatisfactionAnswered: noop,
    onVisitorTransferred: noop,
    onVisitorCallboxDisplayed: noop,
    onTargetingRuleTriggered: noop,

    /*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
    onTargetingRuleWishToDisplay: noop,

    /*________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
    retrieveAuthenticationParameters: (
      callback: RetrieveAuthenticationParametersCallback = noop,
    ) => callback(),
  };

  //_____________________________________________________________
  const aliases = {
    onInvitation: 'onInvitationDisplayed',
    onC2cStatusChanged: 'onCallStatusChanged',
    onOperatorMsg: 'onMessageReceived',
    onVisitorMsg: 'onMessageSent',
  } as const;

  type CallbackAliases = typeof aliases;
  type RetrieveAuthenticationParameters =
    IAdvizeCallbacks['retrieveAuthenticationParameters'];
  type RetrieveAuthenticationParametersCallback =
    Parameters<RetrieveAuthenticationParameters>[0];

  const wrapClientCallback =
    (fn: Function, name: keyof IAdvizeCallbacks) =>
    (...args: unknown[]) => {
      try {
        fn(...args);

        const calledMethods = getInMemoryCalledMethods();

        if (get('visitor:cookiesConsent')) {
          storage.get(
            'calledMethods',
            (value) => {
              const alreadyCalledMethods: CalledMethods = value
                ? JSON.parse(value)
                : calledMethods;

              if (!alreadyCalledMethods.callbacks.includes(name)) {
                alreadyCalledMethods.callbacks.push(name);
                storage.set(
                  'calledMethods',
                  JSON.stringify(alreadyCalledMethods),
                  () =>
                    dispatcher.emit('visitor.sdk.executed', {
                      type: 'callback',
                      method: name,
                    }),
                  'session',
                );
              }
            },
            'session',
          );
        } else if (!calledMethods.callbacks.includes(name)) {
          calledMethods.callbacks.push(name);
          setInMemoryCalledMethods(calledMethods);
        }
      } catch (e) {
        //__________________
      }
    };

  //___________________________________________________________________________
  dispatcher.on('dataPipeline.started', () => {
    getInMemoryCalledMethods().callbacks.forEach((name) => {
      dispatcher.emit('visitor.sdk.executed', {
        type: 'callback',
        method: name,
      });
    });
    getInMemoryCalledMethods().sdk.forEach((name) => {
      dispatcher.emit('visitor.sdk.executed', {
        type: 'webSDK',
        method: name,
      });
    });
  });

  /*________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const userIdIgnoredValues = [
    '',
    'value',
    'values',
    'null',
    'nulle',
    'undefined',
    'nan',
    'infinity',
    'none',
  ];

  const validateCallbackReturnForRetrieveAuthenticationParameters =
    (retrieveAuthenticationParameters: RetrieveAuthenticationParameters) =>
    (callback: RetrieveAuthenticationParametersCallback) =>
      retrieveAuthenticationParameters((auth) => {
        if (!auth) {
          callback();
          return;
        }
        if (Object.values(authenticationTypeEnum).indexOf(auth.type) === -1) {
          //________________________________________
          console.error('Authentication type not recognized');
          throw new Error('Authentication type not recognized');
        }
        if (
          auth.type === authenticationTypeEnum.SECURED &&
          (typeof auth.token !== 'string' || auth.token === '')
        ) {
          //________________________________________
          console.error('token field require a String for SECURED auth');
          throw new Error('token field require a String for SECURED auth');
        }
        if (
          auth.type === authenticationTypeEnum.SIMPLE &&
          (typeof auth.userId !== 'string' ||
            userIdIgnoredValues.indexOf(auth.userId) !== -1)
        ) {
          //________________________________________
          console.error('userId field require a valid String for SIMPLE auth');
          throw new Error(
            'userId field require a valid String for SIMPLE auth',
          );
        }

        callback(auth);
      });

  const clientDefinedCallbacks: Partial<IAdvizeCallbacks> = flow(
    (input) => (input instanceof Object ? input : {}),
    (obj) => Object.entries(obj),
    (c) => c.filter(([, fn]) => typeof fn === 'function'),
    (c) =>
      c.map(([callbackName, fn]) => [
        aliases[callbackName as keyof CallbackAliases] || callbackName,
        fn,
      ]),
    (c) => c.filter(([callbackName]) => callbackName in callbackDefaults),
    (obj) => obj.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  )(iAdvizeCallbacks);

  const DEPRECATED_CALLBACKS: {
    deprecated: keyof IAdvizeCallbacks;
    alternative: string;
  }[] = [
    {
      deprecated: 'onChatHidden',
      alternative: `iAdvize.on('chatbox:statusChange', callbackFn)`,
    },
    {
      deprecated: 'onChatStarted',
      alternative: `iAdvize.on('conversation:idChange', callbackFn)`,
    },
    {
      deprecated: 'onChatEnded',
      alternative: `iAdvize.on('conversation:idChange', callbackFn)`,
    },
    {
      deprecated: 'onCallStarted',
      alternative: `iAdvize.on('conversation:idChange', callbackFn)`,
    },
    {
      deprecated: 'onTargetingRuleTriggered',
      alternative: `iAdvize.on('engagementRule:triggered', callbackFn)`,
    },
    {
      deprecated: 'onRuleStarted',
      alternative: `iAdvize.on('engagementRule:triggered', callbackFn)`,
    },
    {
      deprecated: 'onChatButtonDisplayed',
      alternative: `iAdvize.on('engagementNotification:displayed', callbackFn)`,
    },
    {
      deprecated: 'onCallButtonDisplayed',
      alternative: `iAdvize.on('engagementNotification:displayed', callbackFn)`,
    },
    {
      deprecated: 'onVideoButtonDisplayed',
      alternative: `iAdvize.on('engagementNotification:displayed', callbackFn)`,
    },
    {
      deprecated: 'onInvitationDisplayed',
      alternative: `iAdvize.on('engagementNotification:displayed', callbackFn)`,
    },
    {
      deprecated: 'onTargetingRuleWishToDisplay',
      alternative: `iAdvize.on('engagementNotification:controlled', callbackFn)`,
    },
  ];

  DEPRECATED_CALLBACKS.forEach((deprecatedCallback) => {
    if (
      typeof clientDefinedCallbacks[deprecatedCallback.deprecated] ===
      'function'
    ) {
      deprecate(deprecatedCallback);
    }
  });

  const wrappedClientDefinedCallbacks = Object.entries(
    clientDefinedCallbacks,
  ).reduce((acc, [callbackName, callback]) => {
    return {
      ...acc,
      [callbackName]: wrapClientCallback(
        callbackName === 'retrieveAuthenticationParameters'
          ? validateCallbackReturnForRetrieveAuthenticationParameters(
              callback as RetrieveAuthenticationParameters,
            )
          : (callback as Function),
        callbackName as keyof IAdvizeCallbacks,
      ),
    };
  }, {});

  const callbacks = {
    ...callbackDefaults,
    ...wrappedClientDefinedCallbacks,
  };

  let chatButtonCallbackAlreadyTriggered = false;
  dispatcher.on('ChatButtonDisplayed', () => {
    if (!chatButtonCallbackAlreadyTriggered) {
      chatButtonCallbackAlreadyTriggered = true;
      callbacks.onChatButtonDisplayed();
    }
  });

  let callButtonCallbackAlreadyTriggered = false;
  dispatcher.on('CallButtonDisplayed', () => {
    if (!callButtonCallbackAlreadyTriggered) {
      callButtonCallbackAlreadyTriggered = true;
      callbacks.onCallButtonDisplayed();
    }
  });

  let videoButtonCallbackAlreadyTriggered = false;
  dispatcher.on('VideoButtonDisplayed', () => {
    if (!videoButtonCallbackAlreadyTriggered) {
      videoButtonCallbackAlreadyTriggered = true;
      callbacks.onVideoButtonDisplayed();
    }
  });

  /*____________________________________________________________________*/
  dispatcher.on('call.callBox.open', function () {
    callbacks.onVisitorCallboxDisplayed();
  });

  function clearCallbacksState() {
    chatButtonCallbackAlreadyTriggered = false;
    callButtonCallbackAlreadyTriggered = false;
    videoButtonCallbackAlreadyTriggered = false;
  }

  return {
    callbacks,
    clearCallbacksState,
  };
};
